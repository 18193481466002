import { Box, IconButton, Tooltip } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import VpnKeyIcon from "@material-ui/icons/VpnKey"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { SubAccount, useDeleteSubAccountMutation } from "services/commonApi"
import { useAppDispatch } from "store"
import {
   closeDialog,
   openConfirmationDialog,
   openDialog,
} from "store/slices/dialogs"

type Props = {
   row: SubAccount
}
const RowCTAs = ({ row }: Props) => {
   const dispatch = useAppDispatch()
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const [deleteSubAccount] = useDeleteSubAccountMutation()

   const handleDeleteSubAccount = async () => {
      const confirmed = await dispatch(
         openConfirmationDialog({
            message: t("common_subAccounts.delete_confirmation"),
         })
      ).unwrap()

      if (!confirmed) {
         return
      }

      try {
         await deleteSubAccount({
            email: row.email,
         }).unwrap()
         enqueueSnackbar(t("common_subAccounts.delete_success"), {
            variant: "success",
         })
      } catch (error) {
         enqueueSnackbar(t("common_subAccounts.delete_error"), {
            variant: "error",
         })
      }
      dispatch(closeDialog())
   }
   return (
      <Box display="flex" gridGap={-4}>
         <Tooltip title={t("BusinessRegistry.CambiaPassword")}>
            <IconButton
               onClick={() => {
                  dispatch(
                     openDialog({
                        id: "common_sub_account.change_password",
                        data: {
                           email: row.email,
                        },
                     })
                  )
               }}
            >
               <VpnKeyIcon />
            </IconButton>
         </Tooltip>

         <Tooltip title={t("BusinessRegistry.Modifica")}>
            <IconButton
               onClick={() => {
                  dispatch(
                     openDialog({
                        id: "common_sub_account.edit",
                        data: {
                           subaccount: row,
                        },
                     })
                  )
               }}
            >
               <EditIcon />
            </IconButton>
         </Tooltip>

         <Tooltip title={t("BusinessRegistry.Cancella")}>
            <IconButton onClick={handleDeleteSubAccount}>
               <DeleteIcon />
            </IconButton>
         </Tooltip>
      </Box>
   )
}

export default RowCTAs
