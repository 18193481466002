import { ButtonGroup, IconButton, Tooltip } from "@material-ui/core"
import { Description } from "@material-ui/icons"
import { useTranslation } from "react-i18next"
import { OpenbankingAccount } from "services/openbankingApi/accounts"
import { useAppDispatch } from "store"
import { openDialog } from "store/slices/dialogs"

type Props = {
   account: OpenbankingAccount
}

const RowCTAs = ({ account }: Props) => {
   const dispatch = useAppDispatch()
   const { t } = useTranslation()

   return (
      <ButtonGroup>
         <Tooltip title={t("openbanking.business_registry.account_details")}>
            <IconButton
               onClick={() => {
                  dispatch(
                     openDialog({
                        id: "openbanking_account_details",
                        data: account,
                     })
                  )
               }}
            >
               <Description />
            </IconButton>
         </Tooltip>
      </ButtonGroup>
   )
}

export default RowCTAs
