import {
   Box,
   Button,
   DialogContent,
   DialogTitle,
   FormControl,
   Grid,
   InputLabel,
   MenuItem,
   Select,
} from "@material-ui/core"
import BaseDialog, { BaseDialogActions } from "components/BaseDialog"
import { Field, Formik } from "formik"
import { TextField } from "formik-material-ui"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import {
   useReceiveSepaInstantPaymentMutation,
   useReceiveSepaPaymentMutation,
} from "services/openbankingApi/payments"
import { DialogId, DialogState } from "store/slices/dialogs"
import * as Yup from "yup"

const DIALOG_ID: DialogId = "openbanking_receive_payment"

type FormValues = {
   system: string
   amount: string
   currencyCode: string
   description: string
   email: string
}

const initialValues: FormValues = {
   system: "sepa",
   amount: "",
   currencyCode: "EUR",
   description: "",
   email: "",
}

const validationSchema = Yup.object().shape({
   system: Yup.string().required(),
   amount: Yup.number().required().typeError("Invalid value"),
   currencyCode: Yup.string().required(),
   description: Yup.string().required(),
   email: Yup.string().optional(),
})

type Props = Extract<DialogState, { id: typeof DIALOG_ID }>["data"] &
   BaseDialogActions

const Content = ({ handleClose, accountUuid }: Props) => {
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()

   const [receiveSepaPayment] = useReceiveSepaPaymentMutation()
   const [receiveSepaInstantPayment] = useReceiveSepaInstantPaymentMutation()

   const receivePayment = async ({ system, ...values }: FormValues) => {
      const request =
         system === "sepa" ? receiveSepaPayment : receiveSepaInstantPayment

      const response = await request({
         accountUuid,
         ...values,
      })

      if ("data" in response) {
         enqueueSnackbar(
            t("openbanking.business_registry.messages.receive_payment_success"),
            { variant: "success" }
         )
         handleClose()
         return
      }

      enqueueSnackbar(t("global.messages.generic_error"), {
         variant: "error",
      })
   }

   return (
      <>
         <DialogTitle>
            {t("openbanking.business_registry.receive_payment_dialog.title")}
         </DialogTitle>

         <DialogContent>
            <Formik
               initialValues={initialValues}
               onSubmit={receivePayment}
               validationSchema={validationSchema}
            >
               {({
                  touched,
                  values,
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
               }) => (
                  <form onSubmit={handleSubmit}>
                     <Grid container spacing={3}>
                        <Grid item xs={12}>
                           <FormControl fullWidth>
                              <InputLabel>
                                 {t("openbanking.payments.filters.system")}
                              </InputLabel>

                              <Select
                                 value={values.system}
                                 name="system"
                                 onChange={handleChange}
                              >
                                 {["sepa", "sepa_instant"].map((value) => (
                                    <MenuItem key={value} value={value}>
                                       {value}
                                    </MenuItem>
                                 ))}
                              </Select>
                           </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                           <Field
                              component={TextField}
                              type="text"
                              label={t(
                                 "openbanking.business_registry.receive_payment_dialog.amount"
                              )}
                              name="amount"
                              helperText={
                                 touched.amount != null && errors.amount
                              }
                              error={
                                 touched.amount != null && errors.amount != null
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              fullWidth
                           />
                        </Grid>

                        <Grid item xs={6}>
                           <Field
                              disabled
                              component={TextField}
                              type="text"
                              label={t(
                                 "openbanking.business_registry.receive_payment_dialog.currency"
                              )}
                              name="currencyCode"
                              helperText={
                                 touched.currencyCode != null &&
                                 errors.currencyCode
                              }
                              error={
                                 touched.currencyCode != null &&
                                 errors.currencyCode != null
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              fullWidth
                           />
                        </Grid>

                        <Grid item xs={12}>
                           <Field
                              component={TextField}
                              multiline
                              rows={4}
                              type="text"
                              label={t(
                                 "openbanking.business_registry.receive_payment_dialog.description"
                              )}
                              name="description"
                              helperText={
                                 touched.description != null &&
                                 errors.description
                              }
                              error={
                                 touched.description != null &&
                                 errors.description != null
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              fullWidth
                           />
                        </Grid>

                        <Grid item xs={12}>
                           <Field
                              component={TextField}
                              type="text"
                              label={t(
                                 "openbanking.business_registry.receive_payment_dialog.email"
                              )}
                              name="email"
                              helperText={touched.email != null && errors.email}
                              error={
                                 touched.email != null && errors.email != null
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              fullWidth
                           />
                        </Grid>
                     </Grid>

                     <Box
                        display="flex"
                        justifyContent="flex-end"
                        gridGap={8}
                        mt={2}
                        mb={1}
                     >
                        <Button
                           color="default"
                           variant="contained"
                           onClick={handleClose}
                        >
                           {t("global.cancel")}
                        </Button>

                        <Button
                           disabled={isSubmitting}
                           color="primary"
                           variant="contained"
                           type="submit"
                        >
                           {t("global.submit")}
                        </Button>
                     </Box>
                  </form>
               )}
            </Formik>
         </DialogContent>
      </>
   )
}

const ReceivePaymentDialog = () => (
   <BaseDialog id={DIALOG_ID} fullWidth>
      {(dialogData) => <Content {...dialogData} />}
   </BaseDialog>
)

export default ReceivePaymentDialog
