import { ButtonGroup, IconButton, Tooltip } from "@material-ui/core"
import CodeIcon from "@material-ui/icons/Code"
import DescriptionIcon from "@material-ui/icons/Description"
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf"
import RotateLeftIcon from "@material-ui/icons/RotateLeft"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { GovITInvoice } from "services/gov-it/types"
import { InvoiceType } from "../.."
import { download } from "../../../../../helper/download"
import { useLazyGetInvoiceFileQuery } from "../../../../../services/gov-it"
import { useAppDispatch } from "../../../../../store"
import { openDialog } from "../../../../../store/slices/dialogs"

type Props = {
   item: GovITInvoice
   type: InvoiceType
}

type HandleDownloadFile = {
   uuid: string
   fileName: string
   accept: "application/pdf" | "application/xml"
}
const RowCTA = ({ item, type }: Props) => {
   const { enqueueSnackbar } = useSnackbar()
   const [getInvoiceFile] = useLazyGetInvoiceFileQuery()
   const dispatch = useAppDispatch()
   const { t } = useTranslation()

   const handleDownloadFile = async ({
      uuid,
      fileName,
      accept,
   }: HandleDownloadFile) => {
      const response = await getInvoiceFile({
         uuid: uuid,
         accept: accept,
      })
      if ("error" in response) {
         enqueueSnackbar(t("global.messages.generic_error"), {
            variant: "error",
         })
         return
      }
      if (response.data) {
         download(response.data, fileName)
      }
   }

   const isCustomerInvoice = type === "customer-invoice"

   return (
      <ButtonGroup>
         {item.sdi_file_id && (
            <Tooltip title="Invoice PDF">
               <IconButton
                  onClick={async () => {
                     await handleDownloadFile({
                        uuid: item.uuid,
                        fileName: item.sdi_file_name + ".pdf",
                        accept: "application/pdf",
                     })
                  }}
               >
                  <PictureAsPdfIcon />
               </IconButton>
            </Tooltip>
         )}

         {item.sdi_file_id && (
            <Tooltip title="Invoice XML">
               <IconButton
                  onClick={async () => {
                     await handleDownloadFile({
                        uuid: item.uuid,
                        fileName: item.sdi_file_name,
                        accept: "application/xml",
                     })
                  }}
               >
                  <CodeIcon />
               </IconButton>
            </Tooltip>
         )}

         <Tooltip title="Invoice Details">
            <IconButton
               onClick={() => {
                  dispatch(
                     openDialog({
                        id: "gov_it_invoices_details",
                        data: {
                           invoiceUuid: item.uuid,
                           mode: "info",
                        },
                     })
                  )
               }}
            >
               <DescriptionIcon />
            </IconButton>
         </Tooltip>

         {isCustomerInvoice &&
            !["TD04", "TD08"].includes(item.document_type.trim()) && (
               <Tooltip title={t("CustomerInvoices.crea_credit_note")}>
                  <IconButton
                     onClick={() => {
                        dispatch(
                           openDialog({
                              id: "invoiceSentCreditNote",
                              data: {
                                 invoiceUuid: item.uuid,
                              },
                           })
                        )
                     }}
                  >
                     <RotateLeftIcon />
                  </IconButton>
               </Tooltip>
            )}
      </ButtonGroup>
   )
}

export default RowCTA
