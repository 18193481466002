import { Container, makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useGetUserRecipientCodeQuery } from "services/gov-it"
import BusinessRegistryConfigurationsDialogs from "views/BusinessRegistryConfigurations/Dialogs"
import BusinessRegistryConfigurationsFilters from "views/BusinessRegistryConfigurations/Filters"
import BusinessRegistryConfigurationsTable from "views/BusinessRegistryConfigurations/Table"
import Page from "views/common/components/Page"
import SupplierInvoiceSettings from "views/common/SupplierInvoiceSettings/SupplierInvoiceSettings"
import RecoverInvoicesDialog from "./RecoverInvoicesDialog"

const useStyles = makeStyles((theme) => ({
   root: {
      minHeight: "100%",
      paddingBottom: 16,
      paddingTop: 16,
   },
   menuButton: {
      marginRight: theme.spacing(2),
      color: theme.palette.text.primary,
   },
}))

const codiciDestinatariDaEscludere = [
   "JKKZDGR",
   // codide destinatario di intesi
   "AVDX2J9",
]

const BusinessRegistryConfigurations = () => {
   const classes = useStyles()
   const { t } = useTranslation()
   const { data } = useGetUserRecipientCodeQuery()

   return (
      <>
         <Page className={classes.root} title={t("Menu.Business Registry")}>
            <Container maxWidth={false}>
               {data?.recipient_code &&
                  !codiciDestinatariDaEscludere.includes(
                     data.recipient_code
                  ) && <SupplierInvoiceSettings />}
               <BusinessRegistryConfigurationsFilters />
               <BusinessRegistryConfigurationsTable />
            </Container>

            <BusinessRegistryConfigurationsDialogs />
            <RecoverInvoicesDialog />
         </Page>
      </>
   )
}

export default BusinessRegistryConfigurations
