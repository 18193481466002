import { Box, CircularProgress, Switch, Tooltip } from "@material-ui/core"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useUpdateAccountMutation } from "services/openbankingApi/accounts"

type Props = {
   accountUuid: string
   name: string
   enabled: boolean
}

const EnableAccountToggle = ({ accountUuid, name, enabled }: Props) => {
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const [updateAccount, { isLoading }] = useUpdateAccountMutation()

   const toggleEnableAccount = async () => {
      const response = await updateAccount({
         uuid: accountUuid,
         enabled: !enabled,
      })

      if ("error" in response) {
         enqueueSnackbar(t("global.messages.generic_error"), {
            variant: "error",
         })
         return
      }

      enqueueSnackbar(
         t(
            enabled
               ? "openbanking.business_registry.messages.account_disabled"
               : "openbanking.business_registry.messages.account_enabled",
            { name }
         ),
         { variant: "success" }
      )
   }

   return (
      <>
         {isLoading && (
            <Box
               display="flex"
               justifyContent="flex-start"
               color="grey.500"
               style={{ paddingLeft: "12px" }}
            >
               <CircularProgress size={24} color="inherit" />
            </Box>
         )}

         {!isLoading && (
            <Tooltip
               title={t("openbanking.business_registry.toggle_enable_account")}
            >
               <Switch
                  checked={enabled}
                  onChange={toggleEnableAccount}
                  color="primary"
               />
            </Tooltip>
         )}
      </>
   )
}

export default EnableAccountToggle
