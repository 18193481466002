import { Container, makeStyles } from "@material-ui/core"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { useAppSelector } from "store"
import { selectHasRole } from "store/auth"
import Page from "../../common/components/Page"
import InvoiceDialog from "../../common/InvoiceDetails/InvoiceDialog"
import InvoiceFilterForm, {
   FormFilterType,
} from "../../common/InvoiceFilterForm"
import CreateInvoiceDialog from "./CreateInvoiceDialog"
import CreateCreditNotesDialog from "./CreateInvoiceDialog/CreateCreditNotesDialog"
import { getInvoiceConfigurations } from "./helpers"
import ImportInvoiceDialog from "./ImportInvoiceDialog"
import CustomerSupplierInvoiceTable from "./Table"

export type InvoiceType = "customer-invoice" | "supplier-invoice"
type CustomerSupplierInvoiceProps = {
   type: InvoiceType
}

const CustomerSupplierInvoice = ({ type }: CustomerSupplierInvoiceProps) => {
   const classes = useStyles()
   const navigate = useNavigate()
   const { t } = useTranslation()

   const configs = getInvoiceConfigurations(type)

   const hasAllowedRole = useAppSelector((state) =>
      selectHasRole(state, {
         context: "it.api.acubeapi.com",
         role: configs.role,
      })
   )
   useEffect(() => {
      if (hasAllowedRole) {
         navigate("/")
      }
   }, [hasAllowedRole, navigate])

   return (
      <>
         <Page className={classes.root} title={t(configs.title)}>
            <Container maxWidth={false}>
               <InvoiceFilterForm
                  type={type}
                  table={configs.table}
                  filterType={configs.table as FormFilterType}
               />
               <CustomerSupplierInvoiceTable type={type} />
            </Container>
         </Page>

         <InvoiceDialog table={configs.table} />
         <ImportInvoiceDialog />

         {type === "customer-invoice" && (
            <>
               <CreateCreditNotesDialog />
               <CreateInvoiceDialog />
            </>
         )}
      </>
   )
}

export default CustomerSupplierInvoice

const useStyles = makeStyles((theme) => ({
   root: {
      minHeight: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
   },
   menuButton: {
      marginRight: theme.spacing(2),
      color: theme.palette.text.primary,
   },
}))
