import { LiveHelp, MenuBook } from "@material-ui/icons"
import AnnouncementIcon from "@material-ui/icons/Announcement"
import ArchiveIcon from "@material-ui/icons/Archive"
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate"
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation"
import EqualizerIcon from "@material-ui/icons/Equalizer"
import FingerprintIcon from "@material-ui/icons/Fingerprint"
import ForumIcon from "@material-ui/icons/Forum"
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile"
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn"
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox"
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser"
import OpenWithIcon from "@material-ui/icons/OpenWith"
import PaymentIcon from "@material-ui/icons/Payment"
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck"
import ReceiptIcon from "@material-ui/icons/Receipt"
import SaveIcon from "@material-ui/icons/SaveRounded"
import SettingsIcon from "@material-ui/icons/Settings"
import SubjectIcon from "@material-ui/icons/Subject"
import SwapHoriz from "@material-ui/icons/SwapHoriz"
import UnarchiveIcon from "@material-ui/icons/Unarchive"
import WebAssetIcon from "@material-ui/icons/WebAsset"
import React from "react"
import { Repeat } from "react-feather"
import { ApplicationContext, UserRole } from "store/auth"

type RolesContext = {
   context: ApplicationContext
   rolesToAllowed?: UserRole[]
   rolesToDenied?: UserRole[]
   onlyForSubAccount?: boolean
   allowOnlyForStripeAccount?: boolean
}

type NavbarContextItems = Partial<{
   [key in ApplicationContext]: (
      | {
           href: string
           icon: React.ElementType
           title: string
           external?: boolean
        }
      | {
           subheader: string
           rolesContext?: RolesContext
        }
   )[]
}>

/*export const UTILITY_STRIPE: NavbarContextItems[keyof NavbarContextItems] = [
   {
      subheader: "Menu.Utility",
   },
   {
      href: "https://www.acubeapi.com/en/products/app-stripe-e-invoicing#faq",
      external: true,
      icon: LiveHelp,
      title: "FAQ",
   },
   {
      href: "https://docs.acubeapi.com/documentation/stripe/",
      external: true,
      icon: MenuBook,
      title: "Menu.manual",
   },
]*/

export const NAVBARCONTEXTITEMS: NavbarContextItems = {
   "it.api.acubeapi.com": [
      {
         href: "/dashboard",
         icon: EqualizerIcon,
         title: "Menu.Dashboard",
         rolesContext: {
            context: "it.api.acubeapi.com",
            rolesToAllowed: ["ROLE_SHOW_STATS"],
            onlyForSubAccount: true,
         },
      },
      {
         href: "/api-configurations",
         icon: OpenWithIcon,
         title: "Menu.Integrazione",
         rolesContext: {
            context: "it.api.acubeapi.com",
            rolesToAllowed: ["ROLE_CONFIG"],
         },
      },
      {
         href: "/business-registry-configurations",
         icon: SettingsIcon,
         title: "Menu.Business Registry",
         rolesContext: {
            context: "it.api.acubeapi.com",
            rolesToAllowed: ["ROLE_CONFIG_BRC", "ROLE_CONFIG"],
            //onlyForSubAccount: true,
         },
      },
      {
         subheader: "Menu.Fatture",
         rolesContext: {
            context: "it.api.acubeapi.com",
            rolesToDenied: [
               "ROLE_INVOICING_SUPPLIER_ONLY",
               "ROLE_INVOICING_CUSTOMER_ONLY",
            ],
         },
      },
      {
         href: "/customer-invoice",
         icon: UnarchiveIcon,
         title: "Menu.Fatture Attive",
         rolesContext: {
            context: "it.api.acubeapi.com",
            rolesToDenied: ["ROLE_INVOICING_SUPPLIER_ONLY"],
         },
      },
      {
         href: "/supplier-invoice",
         icon: ArchiveIcon,
         title: "Menu.Fatture Passive",
         rolesContext: {
            context: "it.api.acubeapi.com",
            rolesToDenied: ["ROLE_INVOICING_CUSTOMER_ONLY"],
         },
      },
      {
         href: "/invoice-transfers",
         icon: Repeat,
         title: "Menu.gov_it.invoice_transfers",
         rolesContext: {
            context: "it.api.acubeapi.com",
            rolesToDenied: ["ROLE_INVOICING_SUPPLIER_ONLY"],
         },
      },
      {
         href: "/invoice-drafts",
         icon: InsertDriveFileIcon,
         title: "Menu.gov_it.invoice_drafts",
         rolesContext: {
            context: "it.api.acubeapi.com",
            rolesToAllowed: ["ROLE_INVOICING_DRAFT", "ROLE_INVOICING"],
            rolesToDenied: ["ROLE_INVOICING_SUPPLIER_ONLY"],
         },
      },
      {
         subheader: "Menu.Smart Receipt",
         rolesContext: {
            context: "it.api.acubeapi.com",
            rolesToAllowed: ["ROLE_RECEIPTS"],
         },
      },
      {
         href: "/smart-receipts-sale",
         icon: PlaylistAddCheckIcon,
         title: "Menu.smart_receipt.sale",
         rolesContext: {
            context: "it.api.acubeapi.com",
            rolesToAllowed: ["ROLE_RECEIPTS"],
         },
      },
      {
         href: "/smart-receipts-return",
         icon: KeyboardReturnIcon,
         title: "Menu.smart_receipt.return",
         rolesContext: {
            context: "it.api.acubeapi.com",
            rolesToAllowed: ["ROLE_RECEIPTS"],
         },
      },
      {
         href: "/smart-receipts-void",
         icon: CancelPresentationIcon,
         title: "Menu.smart_receipt.void",
         rolesContext: {
            context: "it.api.acubeapi.com",
            rolesToAllowed: ["ROLE_RECEIPTS"],
         },
      },
      {
         subheader: "Menu.NSO Ordering",
      },
      {
         href: "/incoming-orders",
         icon: MoveToInboxIcon,
         title: "Menu.Incoming Orders",
      },
      {
         href: "/outgoing-orders",
         icon: OpenInBrowserIcon,
         title: "Menu.Outgoing Orders",
      },
      {
         href: "/incoming-order-responses",
         icon: ReceiptIcon,
         title: "Menu.Incoming Order Responses",
      },
      {
         href: "/outgoing-order-responses",
         icon: ReceiptIcon,
         title: "Menu.Outgoing Order Responses",
      },
      {
         subheader: "Menu.Preservation",
         rolesContext: {
            context: "preservation.api.acubeapi.com",
            //role: "ROLE_READER",
         },
      },
      {
         href: "/preservation-documents",
         icon: SaveIcon,
         title: "Menu.Preservation Documents",
         rolesContext: {
            context: "preservation.api.acubeapi.com",
            //role: "ROLE_READER",
         },
      },
      {
         subheader: "Menu.Sistema Tessera Sanitaria",
      },
      {
         href: "/expenses",
         icon: ReceiptIcon,
         title: "Menu.Expenses",
      },
      {
         subheader: "Menu.Utility",
      },
      {
         href: `${process.env.REACT_APP_BASE_URL}/docs.html`,
         external: true,
         icon: SubjectIcon,
         title: "Menu.Documentazione",
      },
      {
         href: "https://groups.google.com/a/a-cube.io/forum/#!categories/forum-acubeapi",
         external: true,
         icon: ForumIcon,
         title: "Menu.Forum",
      },
      {
         href: "https://acubeapi.com/",
         external: true,
         icon: WebAssetIcon,
         title: "acubeapi.com",
      },
      {
         href: "https://www.acubeapi.com/en/products/app-stripe-e-invoicing#faq",
         external: true,
         icon: LiveHelp,
         title: "FAQ",
         rolesContext: {
            context: "it.api.acubeapi.com",
            allowOnlyForStripeAccount: true,
         },
      },
      {
         href: "https://docs.acubeapi.com/documentation/stripe/",
         external: true,
         icon: MenuBook,
         title: "Menu.manual",
         rolesContext: {
            context: "it.api.acubeapi.com",
            allowOnlyForStripeAccount: true,
         },
      },
      {
         subheader: "Menu.Newsletter",
      },
      {
         href: "https://us19.campaign-archive.com/home/?u=bc126b17bf2d7528fb987bd19&id=eb0a621800",
         external: true,
         icon: AnnouncementIcon,
         title: "Menu.News archive",
      },
      {
         href: "https://acubeapi.us19.list-manage.com/subscribe?u=bc126b17bf2d7528fb987bd19&id=eb0a621800",
         external: true,
         icon: WebAssetIcon,
         title: "Menu.Newsletter subscribe",
      },
   ],
   "peppol.api.acubeapi.com": [
      {
         href: "/dashboard",
         icon: EqualizerIcon,
         title: "Menu.Dashboard",
      },
      {
         href: "/peppol/webhooks",
         icon: OpenWithIcon,
         title: "Menu.Webhooks",
      },
      {
         href: "/peppol/legal-entities",
         icon: OpenWithIcon,
         title: "Menu.peppol.legal_entities",
      },
      {
         subheader: "Menu.Fatture",
      },
      {
         href: "/peppol/incoming-invoices",
         icon: ArchiveIcon,
         title: "Menu.Incoming Invoices",
      },
      {
         href: "/peppol/outgoing-invoices",
         icon: UnarchiveIcon,
         title: "Menu.Outgoing Invoices",
      },
      {
         href: "/peppol/incoming-credit-notes",
         icon: UnarchiveIcon,
         title: "Menu.Incoming Credit Notes",
      },
      {
         href: "/peppol/outgoing-credit-notes",
         icon: ArchiveIcon,
         title: "Menu.Outgoing Credit Notes",
      },
      {
         subheader: "Menu.Ordering",
      },
      {
         href: "/peppol/incoming-orders",
         icon: MoveToInboxIcon,
         title: "Menu.Incoming Orders",
      },
      {
         href: "/peppol/outgoing-orders",
         icon: OpenInBrowserIcon,
         title: "Menu.Outgoing Orders",
      },
      {
         href: "/peppol/incoming-order-responses",
         icon: ReceiptIcon,
         title: "Menu.Incoming Order Responses",
      },
      {
         href: "/peppol/outgoing-order-responses",
         icon: ReceiptIcon,
         title: "Menu.Outgoing Order Responses",
      },
      {
         href: "/peppol/incoming-despatch-advices",
         icon: AssignmentLateIcon,
         title: "Menu.Incoming Despatch Advices",
      },
      {
         href: "/peppol/outgoing-despatch-advices",
         icon: AssignmentLateIcon,
         title: "Menu.Outgoing Despatch Advices",
      },
      {
         subheader: "Menu.Utility",
      },
      {
         // Sandbox link https://peppol-sandbox.api.acubeapi.com/docs.html
         href: `${process.env.REACT_APP_PEPPOL_BASE_URL}/docs.html`,
         external: true,
         icon: SubjectIcon,
         title: "Menu.Documentazione",
      },
   ],
   "pl.api.acubeapi.com": [
      {
         href: "/gov-pl/legal-entities",
         icon: FingerprintIcon,
         title: "Menu.gov_pl.legal_entities",
      },
      {
         href: "/gov-pl/webhooks",
         icon: OpenWithIcon,
         title: "Menu.gov_pl.webhooks",
      },
      {
         subheader: "Menu.gov_pl.invoices",
      },
      {
         href: "/gov-pl/outgoing-invoices",
         icon: UnarchiveIcon,
         title: "Menu.gov_pl.outgoing_invoices",
      },
      {
         href: "/gov-pl/incoming-invoices",
         icon: ArchiveIcon,
         title: "Menu.gov_pl.incoming_invoices",
      },
      {
         subheader: "Menu.Utility",
      },
      {
         href: "https://docs.acubeapi.com/documentation/gov-pl",
         external: true,
         icon: SubjectIcon,
         title: "Menu.Documentazione",
      },
      {
         href: "https://groups.google.com/a/a-cube.io/g/forum-a-cube-api-poland",
         external: true,
         icon: ForumIcon,
         title: "Menu.Forum",
      },
      {
         href: "https://www.acubeapi.com/en/products/api-e-invoicing-poland",
         external: true,
         icon: WebAssetIcon,
         title: "acubeapi.com",
      },
   ],
   "ob.api.acubeapi.com": [
      {
         href: "/openbanking/webhooks",
         icon: OpenWithIcon,
         title: "Menu.openbanking.webhooks",
      },
      {
         href: "/openbanking/business-registry",
         icon: SettingsIcon,
         title: "Menu.openbanking.business_registry",
      },
      {
         href: "/openbanking/transactions",
         icon: SwapHoriz,
         title: "Menu.openbanking.transactions",
      },
      {
         href: "/openbanking/payments",
         icon: PaymentIcon,
         title: "Menu.openbanking.payments",
      },
      {
         subheader: "Menu.Utility",
      },
      {
         href: "https://docs.acubeapi.com/documentation/open-banking/",
         external: true,
         icon: SubjectIcon,
         title: "Menu.Documentazione",
      },
      {
         href: "https://groups.google.com/u/0/a/a-cube.io/g/forum-a-cube-api-openbanking",
         external: true,
         icon: ForumIcon,
         title: "Menu.Forum",
      },
      {
         href: "https://acubeapi.com",
         external: true,
         icon: WebAssetIcon,
         title: "acubeapi.com",
      },
   ],
}

export const contextExistInNavBarContextItems = (
   context: ApplicationContext
) => {
   return NAVBARCONTEXTITEMS.hasOwnProperty(context)
}

export const getOneContextExistInNavBarContextItems = (
   contexts: ApplicationContext[]
) => {
   return contexts.find((c) => contextExistInNavBarContextItems(c)) ?? null
}
