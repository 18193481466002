import {
   Box,
   Button,
   List,
   ListItem,
   ListItemSecondaryAction,
   ListItemText,
   Typography,
} from "@material-ui/core"
import { download } from "helper/download"
import { formatPrice } from "helper/numberFormater"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useLazyGetDetailOrPDFSmartReceiptQuery } from "services/gov-it/smartReceipt"
import {
   SmartReceiptDetails,
   SmartReceiptItemPDFDetail,
} from "services/gov-it/smartReceipt/type"
import LabelYesNo from "views/common/LabelYesNo"
import TableWithAccordion, {
   FormatPriceType,
} from "views/common/TableWithAccordion"

const GetItemKeys = [
   "total_taxable_amount",
   "total_uncollected_amount",
   "deductible_amount",
   "total_vat_amount",
   "total_discount",
   "total_gross_discount",
   "discount",
]

const GetSubItemPriceItemKeys = [
   "gross_price",
   "gross_discount",
   "unit_price",
   "return",
   "taxable_amount",
   "net_taxable_amount",
   "vat_amount",
   "total_amount",
   "unit_discount",
]

const booleanKeys = ["complimentary"]

type GetItemType = {
   item: SmartReceiptDetails
}
const GetItem = ({ item }: GetItemType) => {
   const { t, i18n } = useTranslation()

   const getListItem = (
      key: string,
      value: string | number | boolean,
      divider?: boolean
   ) => {
      return (
         <ListItem divider={divider ?? true}>
            <ListItemText primary={t(`smartReceipt.${key}`)} />
            <ListItemSecondaryAction>
               <ListItemText
                  style={{
                     color:
                        GetSubItemPriceItemKeys.includes(key) ||
                        GetItemKeys.includes(key)
                           ? value < 0
                              ? "red"
                              : value > 0
                              ? "green"
                              : "black"
                           : "black",
                     fontWeight: "bold",
                  }}
                  primary={
                     GetSubItemPriceItemKeys.includes(key) ||
                     GetItemKeys.includes(key) ? (
                        formatPrice(i18n.language, value, undefined, true)
                     ) : booleanKeys.includes(key) ? (
                        <LabelYesNo value={value} />
                     ) : (
                        value
                     )
                  }
               />
            </ListItemSecondaryAction>
         </ListItem>
      )
   }

   const getFieldForTableAccordion = (
      names: (keyof SmartReceiptItemPDFDetail)[]
   ) => {
      return names.map((name) => ({
         name: name as keyof SmartReceiptItemPDFDetail,
         label: t(`smartReceipt.${name}`),
         format: (GetSubItemPriceItemKeys.includes(name) ||
         GetItemKeys.includes(name)
            ? "price"
            : booleanKeys.includes(name)
            ? "boolean"
            : undefined) as FormatPriceType,
      }))
   }
   return (
      <>
         {GetItemKeys.map((key, index) =>
            getListItem(
               key,
               item[key as keyof Omit<SmartReceiptDetails, "items">],
               index !== GetItemKeys.length - 1
            )
         )}

         <ListItem
            style={{
               width: "100%",
               padding: "0",
               display: "flex",
               justifyItems: "center",
               alignItems: "flex-start",
               flexDirection: "column",
            }}
         >
            <Typography
               style={{
                  fontWeight: 500,
                  color: "#54BACB",
                  fontSize: "0.875rem",
                  flexShrink: 0,
                  lineHeight: 1.75,
                  whiteSpace: "normal",
                  letterSpacing: "0.02857em",
                  textTransform: "uppercase",
                  padding: "10px",
               }}
            >
               items
            </Typography>

            <TableWithAccordion<SmartReceiptItemPDFDetail>
               data={item.items}
               keyField="id"
               primaryFields={getFieldForTableAccordion([
                  "quantity",
                  "description",
                  "vat_rate",
                  "gross_price",
                  "gross_discount",
                  "complimentary",
               ])}
               secondaryFields={getFieldForTableAccordion([
                  "unit_price",
                  "return",
                  "taxable_amount",
                  "net_taxable_amount",
                  "vat_amount",
                  "total_amount",
                  "unit_discount",
               ])}
            />
         </ListItem>
      </>
   )
}

type DownloadPDFType = {
   item: SmartReceiptDetails
}
const DownloadPDF = ({ item }: DownloadPDFType) => {
   const { t } = useTranslation()
   const [getDetailOrPDFSmartReceipt, { isLoading }] =
      useLazyGetDetailOrPDFSmartReceiptQuery()
   const { enqueueSnackbar } = useSnackbar()

   const handleDownloadPDF = async () => {
      const response = await getDetailOrPDFSmartReceipt({
         id: item.uuid,
         accept: "application/pdf",
      })

      if ("error" in response) {
         enqueueSnackbar(t("global.messages.generic_error"), {
            variant: "error",
         })
         return
      }
      if (response.data) {
         download(response.data, item.document_number + ".pdf")
      }
   }
   return (
      <Box
         style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            margin: "10px",
         }}
      >
         <Button
            variant="contained"
            color="primary"
            onClick={handleDownloadPDF}
            disabled={isLoading}
         >
            {isLoading ? t("global.loading") : t("smartReceipt.download_pdf")}
         </Button>
      </Box>
   )
}

type TabPanelInfoProps = {
   item: SmartReceiptDetails
   value?: number
   index?: number
}
const TabPanelReceiptDetails = ({ item, value, index }: TabPanelInfoProps) => {
   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`scrollable-prevent-tabpanel-${index}`}
         aria-labelledby={`scrollable-prevent-tab-${index}`}
      >
         <DownloadPDF item={item} />
         <List
            style={{
               width: "100%",
               padding: "0",
               margin: "0",
            }}
         >
            <GetItem item={item} />
         </List>
      </div>
   )
}

export default TabPanelReceiptDetails
