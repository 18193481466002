import { BusinessRegistryConfigurationType } from "services/gov-it/businessRegistryConfigurations/types"

export const formatBusinessRegistryConfigurations = (
   items: BusinessRegistryConfigurationType[]
) => {
   return items.map((row) => {
      return {
         fiscal_id: row.fiscal_id ? row.fiscal_id : "",
         email: row.email,
         name: row.name,
         supplier_invoice_enabled: row.supplier_invoice_enabled,
         supplier_invoice:
            row.supplier_invoice_enabled === null
               ? "false"
               : row.supplier_invoice_enabled.toString(),
         signature:
            row.apply_signature === null
               ? "false"
               : row.apply_signature.toString(),
         apply_legal_storage:
            row.apply_legal_storage === null
               ? "false"
               : row.apply_legal_storage.toString(),
         receipts_enabled: row.receipts_enabled ? row.receipts_enabled : false,
      }
   }) as unknown as BusinessRegistryConfigurationType[]
}
