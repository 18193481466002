import { BUSINESS_REGISTRY_CONFIGURATIONS } from "endpoints"
import { buildSearchParams } from "helper/buildSearchParams"
import { govItApi, GovITPaginationParams } from "services/gov-it"
import { BusinessRegistryConfigurationType } from "services/gov-it/businessRegistryConfigurations/types"
import { HydraResponse } from "services/types"

type GetBusinessRegistryConfigurationsParams = GovITPaginationParams & {
   receipts_enabled?: 0 | 1
}

type BusinessRegistryConfigurationsResponse =
   HydraResponse<BusinessRegistryConfigurationType>

type BusinessRegistryConfigurationResponse = BusinessRegistryConfigurationType

type Nullable<T> = {
   [K in keyof T]: Nullable<T[K]> | null
}

type EditBusinessRegistryConfigurationParams = Nullable<
   Omit<
      BusinessRegistryConfigurationType,
      | "email_for_preservation_requested_at"
      | "supplier_invoice"
      | "legal_storage_active"
      | "signature"
      | "api_configurations"
   >
> & {
   api_configurations?: string[]
}

type CreateBusinessRegistryConfigurationParams = Omit<
   BusinessRegistryConfigurationType,
   | "fiscal_id"
   | "email_for_preservation_requested_at"
   | "supplier_invoice"
   | "legal_storage_active"
   | "signature"
   | "api_configurations"
> & {
   api_configurations?: string[]
}

const govItApiBusinessRegistryConfigurationsApi = govItApi.injectEndpoints({
   endpoints: (builder) => ({
      getBusinessRegistryConfigurations: builder.query<
         BusinessRegistryConfigurationsResponse,
         GetBusinessRegistryConfigurationsParams
      >({
         query: (params) => ({
            url: `${BUSINESS_REGISTRY_CONFIGURATIONS}${buildSearchParams({
               ...params,
               ...(params.refetch
                  ? { refetch: params.refetch * Math.random() }
                  : { refetch: Math.random() * 100 }),
            })}`,
         }),
         providesTags: ["BUSINESS_REGISTRY_CONFIGURATIONS"],
      }),

      getBusinessRegistryConfiguration: builder.query<
         BusinessRegistryConfigurationResponse,
         { fiscal_id: string }
      >({
         query: ({ fiscal_id }) => ({
            url: `${BUSINESS_REGISTRY_CONFIGURATIONS}/${fiscal_id}`,
         }),
         providesTags: ["BUSINESS_REGISTRY_CONFIGURATIONS"],
      }),

      createBusinessRegistryConfiguration: builder.mutation<
         BusinessRegistryConfigurationResponse,
         CreateBusinessRegistryConfigurationParams
      >({
         query: (body) => ({
            url: BUSINESS_REGISTRY_CONFIGURATIONS,
            method: "POST",
            body,
         }),
         invalidatesTags: ["BUSINESS_REGISTRY_CONFIGURATIONS"],
      }),

      editBusinessRegistryConfiguration: builder.mutation<
         BusinessRegistryConfigurationResponse,
         EditBusinessRegistryConfigurationParams
      >({
         query: ({ fiscal_id, ...body }) => ({
            url: `${BUSINESS_REGISTRY_CONFIGURATIONS}/${fiscal_id}`,
            method: "PUT",
            body,
         }),
         invalidatesTags: ["BUSINESS_REGISTRY_CONFIGURATIONS"],
      }),

      deleteBusinessRegistryConfiguration: builder.mutation<
         void,
         { fiscal_id: string }
      >({
         query: ({ fiscal_id }) => ({
            url: `${BUSINESS_REGISTRY_CONFIGURATIONS}/${fiscal_id}`,
            method: "DELETE",
         }),
         invalidatesTags: ["BUSINESS_REGISTRY_CONFIGURATIONS"],
      }),
   }),
})

export const {
   useGetBusinessRegistryConfigurationsQuery,
   useGetBusinessRegistryConfigurationQuery,
   useLazyGetBusinessRegistryConfigurationQuery,
   useCreateBusinessRegistryConfigurationMutation,
   useEditBusinessRegistryConfigurationMutation,
   useDeleteBusinessRegistryConfigurationMutation,
} = govItApiBusinessRegistryConfigurationsApi
