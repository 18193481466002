import { Container, makeStyles } from "@material-ui/core"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { useAppSelector } from "store"
import { selectHasRole } from "store/auth"
import Page from "views/common/components/Page"
import NewSubAccount from "views/settings/SubAccounts/Components/NewSubAccount"
import TableFilters from "views/settings/SubAccounts/Filters"
import EditSubAccountDialog from "views/settings/SubAccounts/SubAccountsDialog/EditSubAccountDialog"
import NewSubAccountDialog from "views/settings/SubAccounts/SubAccountsDialog/NewSubAccountDialog"
import UpdateSubAccountPasswordDialog from "views/settings/SubAccounts/SubAccountsDialog/UpdateSubAccountPasswordDialog"
import SubAccountsTable from "views/settings/SubAccounts/SubAccountsTable"

const useStyles = makeStyles((theme) => ({
   root: {
      minHeight: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
   },
}))

const Content = () => {
   const classes = useStyles()
   const { t } = useTranslation()

   return (
      <Page className={classes.root} title={t("common_subAccounts.title")}>
         <Container maxWidth={false}>
            <NewSubAccount />

            <TableFilters />

            <SubAccountsTable />
         </Container>

         <NewSubAccountDialog />
         <EditSubAccountDialog />
         <UpdateSubAccountPasswordDialog />
      </Page>
   )
}

const SubAccountsPage = () => {
   const navigate = useNavigate()
   const isAllowedToSubAccounts = useAppSelector((state) =>
      selectHasRole(state, {
         context: "common.api.acubeapi.com",
         role: "ROLE_SUB_ACCOUNT_MANAGER",
      })
   )

   useEffect(() => {
      if (!isAllowedToSubAccounts) {
         navigate("/")
      }
   }, [isAllowedToSubAccounts, navigate])

   if (!isAllowedToSubAccounts) {
      return null
   }

   return <Content />
}

export default SubAccountsPage
