import { HydraResponse } from "services/types"
import { openbankingApi, OpenbankingPaginationParams } from ".."

export type OpenbankingAccount = {
   uuid: string
   fiscalId: string
   accountId: string
   providerName: string
   name: string
   nature: string
   balance: string
   currencyCode: string
   enabled: boolean
   consentExpiresAt: string
   iban: string | null
   bban: string | null
   swift: string | null
}

type GetAccountsResponse = HydraResponse<OpenbankingAccount>

type GetAccountsParams = OpenbankingPaginationParams

type UpdateAccountResponse = OpenbankingAccount

type UpdateAccountParams = {
   enabled: boolean
}

const openbankingAccountsApi = openbankingApi.injectEndpoints({
   endpoints: (builder) => ({
      getAccounts: builder.query<
         GetAccountsResponse,
         GetAccountsParams & { fiscalId: string }
      >({
         query: ({ fiscalId, ...params }) => ({
            url: `/business-registry/${fiscalId}/accounts`,
            params,
            headers: {
               Accept: "application/ld+json",
            },
         }),
         providesTags: ["ACCOUNTS"],
      }),

      updateAccount: builder.mutation<
         UpdateAccountResponse,
         UpdateAccountParams & { uuid: string }
      >({
         query: ({ uuid, ...body }) => ({
            url: `/accounts/${uuid}`,
            method: "PUT",
            body,
         }),
         invalidatesTags: ["ACCOUNTS", "TRANSACTIONS"],
      }),
   }),
})

export const { useGetAccountsQuery, useUpdateAccountMutation } =
   openbankingAccountsApi
