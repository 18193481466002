import { Box } from "@material-ui/core"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { Alert, AlertTitle } from "@material-ui/lab"
import BaseDialog, { BaseDialogActions } from "components/BaseDialog"
import { FormikHelpers } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import { useImportCustomerInvoiceMutation } from "services/gov-it"
import { useAppDispatch } from "store"
import { DialogId } from "store/slices/dialogs"
import { TableName, updateRefetch } from "store/tablesState"
import { persistTableRefresh } from "store/tablesState/utils"
import ImportInvoiceForm, { FormValues } from "./CustomerImportInvoiceForm"

const DIALOG_ID: DialogId = "customer.invoices.import_invoice"
const table: TableName = "customer.invoices"

type Error = {
   propertyPath: string
   message: string
}
const Content = ({ handleClose }: BaseDialogActions) => {
   const { t } = useTranslation()
   const [errors, setErrors] = React.useState<Error[]>([])
   const [message, setMessage] = React.useState<string | null>(null)
   const genericError: Error = {
      propertyPath: "generic",
      message: "An error occurred",
   }
   const dispatch = useAppDispatch()

   const [importCustomerInvoice, { isLoading }] =
      useImportCustomerInvoiceMutation()

   const validationValues = (
      values: FormValues,
      { setFieldError }: FormikHelpers<FormValues>
   ) => {
      let isValid = true
      if (values.invoice === null) {
         setFieldError("invoice", "Please upload invoice file")
         isValid = false
      }

      if (values.invoice_file_name === "" && values.sdi_id === "") {
         values.notifications.map((notification, index) => {
            if (notification.value == null) {
               setFieldError(
                  `notifications.${index}.value`,
                  "Please upload notification file"
               )
               isValid = false
            }
            if (notification.key == null) {
               setFieldError(
                  `notifications.${index}.key`,
                  "Please select notification type"
               )
               isValid = false
            }
         })
      }
      return isValid
   }

   const formatNotifications = (values: FormValues) => {
      return values.notifications
         .filter(
            (notification) =>
               notification.key !== null &&
               notification.key !== undefined &&
               notification.value !== null &&
               notification.value !== undefined
         )
         .reduce((a, b) => {
            return Object.assign(a, {
               [b.key as string]: b.value,
            })
         }, {} as { [key in "RC" | "MC" | "NS" | "DT" | "NE" | "AT" | "EC"]?: string })
   }
   const handleSave = async (
      values: FormValues,
      formikHelpers: FormikHelpers<FormValues>
   ) => {
      setErrors([])
      setMessage(null)
      const { setFieldError } = formikHelpers
      if (!validationValues(values, formikHelpers)) {
         return
      }

      const notifications = formatNotifications(values)

      const response = await importCustomerInvoice({
         invoice: values.invoice as string,
         ...(values.invoice_file_name !== "" && {
            invoice_file_name: values.invoice_file_name,
         }),
         ...(values.sdi_id !== "" && { sdi_id: values.sdi_id }),
         ...(notifications &&
            Object.entries(notifications).length > 0 && {
               notifications: notifications,
            }),
      })

      if ("error" in response && "data" in response.error) {
         // @ts-ignore
         if ("violations" in response.error.data) {
            const violations = response.error.data.violations as Error[]
            setErrors(violations)
            violations.map((violation) => {
               if (violation.propertyPath.startsWith("notifications")) {
                  const index = violation.propertyPath.split(".")[1]
                  setFieldError(
                     `notifications.${index}.${
                        violation.propertyPath.split(".")[2]
                     }`,
                     violation.message
                  )
               } else {
                  setFieldError(violation.propertyPath, violation.message)
               }
            })

            return
         }
         setErrors([genericError])
         return
      }

      if ("data" in response) {
         setMessage(
            `Your invoice has been import successfully ${response.data.uuid}`
         )
         persistTableRefresh(table)
         dispatch(
            updateRefetch({
               table: table,
            })
         )
         return
      }
   }

   return (
      <>
         <DialogTitle id="create-invoice-dialog-title">
            {t("CustomerInvoices.ImportInvoice.import_invoice")}
         </DialogTitle>

         <DialogContent>
            {errors.length > 0 && (
               <Box mt={2} mb={2}>
                  <Alert
                     severity="error"
                     onClose={() => {
                        setErrors([])
                     }}
                  >
                     <AlertTitle>
                        {t("CustomerInvoices.Components.Validation Error")}
                     </AlertTitle>
                     {errors.map(function (error) {
                        return (
                           <p>
                              <strong>{error.propertyPath}</strong>{" "}
                              {error.message}
                           </p>
                        )
                     })}
                  </Alert>
               </Box>
            )}

            {message && (
               <Box mt={2} mb={2}>
                  <Alert
                     severity="success"
                     style={{
                        margin: 2,
                     }}
                     onClose={() => {
                        setMessage(null)
                     }}
                  >
                     <AlertTitle>
                        {t("CustomerInvoices.Components.Invoice imported")}
                     </AlertTitle>
                     {message}
                  </Alert>
               </Box>
            )}

            <ImportInvoiceForm
               onSubmit={handleSave}
               isLoading={isLoading}
               handleClose={handleClose}
            />
         </DialogContent>
      </>
   )
}

const ImportInvoiceDialog = () => {
   return (
      <BaseDialog id={DIALOG_ID} maxWidth="lg" fullWidth>
         {(data) => <Content {...data} />}
      </BaseDialog>
   )
}

export default ImportInvoiceDialog
