import { Box } from "@material-ui/core"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { Alert, AlertTitle } from "@material-ui/lab"
import BaseDialog from "components/BaseDialog"
import { FormikHelpers } from "formik"
import { useSnackbar } from "notistack"
import React from "react"
import { useTranslation } from "react-i18next"
import {
   useCreateBusinessRegistryConfigurationMutation,
   useEditBusinessRegistryConfigurationMutation,
   useGetBusinessRegistryConfigurationQuery,
} from "services/gov-it/businessRegistryConfigurations"
import { useAppDispatch } from "store"
import { TableName, updateRefetch } from "store/tablesState"
import { persistTableRefresh } from "store/tablesState/utils"
import BusinessRegistryConfigurationsForm, {
   FormValues,
} from "views/BusinessRegistryConfigurations/Dialogs/Form"
import Loader from "views/common/Loader"
import Statistics from "views/common/Statistics"

const table: TableName = "api.configurations"
const DIALOG_ID = "business.registry.configurations"

type ViolationType = {
   propertyPath: string
   message: string
}

type Props = {
   handleClose: () => void
   fiscal_id: string | null
   mode?: "stats"
}
function Content({ handleClose, fiscal_id, mode }: Props) {
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const [errors, setErrors] = React.useState<ViolationType[] | null>([])
   const [message, setMessage] = React.useState<string | null>(null)
   const dispatch = useAppDispatch()

   const { data, isLoading, error } = useGetBusinessRegistryConfigurationQuery(
      { fiscal_id: fiscal_id! },
      { skip: !fiscal_id }
   )

   const [editBusinessRegistryConfiguration, { isLoading: isUpdating }] =
      useEditBusinessRegistryConfigurationMutation()

   const [createBusinessRegistryConfiguration, { isLoading: isCreating }] =
      useCreateBusinessRegistryConfigurationMutation()

   const handleSaveBusinessRegistryConfiguration = async (
      uuid: string | null,
      values: FormValues,
      { setFieldError }: FormikHelpers<FormValues>
   ) => {
      setErrors(null)
      setMessage(null)
      let response
      let apiConfigurations = values.api_configurations
         .map((a) => a.value)
         .filter((a) => a)

      if (uuid) {
         response = await editBusinessRegistryConfiguration({
            ...values,
            fiscal_id: uuid,
            api_configurations: apiConfigurations as string[],
         })
      } else {
         response = await createBusinessRegistryConfiguration({
            ...values,
            api_configurations: apiConfigurations as string[],
         })
      }

      if (
         "error" in response &&
         "data" in response.error &&
         "violations" in (response.error.data as any)
      ) {
         const violations = (response.error.data as any)
            ?.violations as ViolationType[]
         setErrors(violations)
         violations.forEach((v) => setFieldError(v.propertyPath, v.message))
         enqueueSnackbar(t("Integrazione.Validation Error"), {
            variant: "error",
         })
         return
      }

      if ("data" in response) {
         const evento = response.data
         if (uuid) {
            setMessage(`${t("Integrazione.Aggiornato")} `)
            enqueueSnackbar(`${t("Integrazione.Aggiornato")}`, {
               variant: "success",
            })
         } else {
            setMessage(
               `${t("Integrazione.Salvato")} - UUID: ${evento?.fiscal_id}`
            )
            enqueueSnackbar(
               `${t("Integrazione.Salvato")} - UUID: ${evento?.fiscal_id}`,
               { variant: "success" }
            )
         }

         persistTableRefresh(table)
         dispatch(
            updateRefetch({
               table,
            })
         )
      }
   }

   const businessRegistryConfiguration = data

   return (
      <>
         {isLoading ? (
            <Loader />
         ) : (
            <>
               {mode === "stats" && fiscal_id ? (
                  <DialogContent>
                     <Box mt={2} mb={2}>
                        <Statistics fiscalId={fiscal_id} />
                     </Box>
                  </DialogContent>
               ) : (
                  <>
                     <DialogTitle id="business-configuration-dialog-title">
                        {"Business Registry"}{" "}
                        {businessRegistryConfiguration &&
                        businessRegistryConfiguration.fiscal_id
                           ? businessRegistryConfiguration.fiscal_id
                           : null}
                     </DialogTitle>

                     <DialogContent>
                        {errors && errors.length > 0 && (
                           <Box mt={2} mb={2}>
                              <Alert
                                 severity="error"
                                 onClose={() => {
                                    setErrors([])
                                 }}
                              >
                                 <AlertTitle>
                                    {t("Integrazione.Validation Error")}
                                 </AlertTitle>
                                 {errors.map(function (error) {
                                    return (
                                       <p>
                                          <strong>{error.propertyPath}</strong>{" "}
                                          {error.message}
                                       </p>
                                    )
                                 })}
                              </Alert>
                           </Box>
                        )}

                        {message && (
                           <Box mt={2} mb={2}>
                              <Alert
                                 severity="success"
                                 style={{
                                    margin: 2,
                                 }}
                                 onClose={() => {
                                    setMessage(null)
                                 }}
                              >
                                 <AlertTitle>{t("global.success")}</AlertTitle>
                                 {message}
                              </Alert>
                           </Box>
                        )}

                        <BusinessRegistryConfigurationsForm
                           handleClose={handleClose}
                           isLoading={isUpdating || isLoading || isCreating}
                           handleSaveBusinessRegistryConfiguration={
                              handleSaveBusinessRegistryConfiguration
                           }
                           businessRegistryConfiguration={
                              businessRegistryConfiguration
                           }
                        />
                     </DialogContent>
                  </>
               )}
            </>
         )}
      </>
   )
}

const BusinessRegistryConfigurationsDialogs = () => {
   return (
      <BaseDialog id={DIALOG_ID} maxWidth="xl" fullWidth>
         {(data) => <Content {...data} />}
      </BaseDialog>
   )
}

export default BusinessRegistryConfigurationsDialogs
