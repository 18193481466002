import { yupResolver } from "@hookform/resolvers/yup"
import {
   Box,
   Button,
   Checkbox,
   FormControl,
   FormControlLabel,
   FormHelperText,
   Grid,
   Select,
   TextField,
   Typography,
} from "@material-ui/core"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import { Alert, AlertTitle } from "@material-ui/lab"
import { cleanedJsonObject } from "helper/cleanedJsonObject"
import { useSnackbar } from "notistack"
import { useState } from "react"
import {
   Controller,
   SubmitHandler,
   useFieldArray,
   useForm,
} from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
   useGetBusinessRegistryConfigurationsQuery,
   useLazyGetBusinessRegistryConfigurationQuery,
} from "services/gov-it/businessRegistryConfigurations"
import { useCreateSmartReceiptMutation } from "services/gov-it/smartReceipt"
import { CreateSmartReceiptBody } from "services/gov-it/smartReceipt/type"
import { useAppDispatch } from "store"
import { closeDialog } from "store/slices/dialogs"
import { TableName, updateRefetch } from "store/tablesState"
import { persistTableRefresh } from "store/tablesState/utils"
import { formatBusinessRegistryConfigurations } from "views/BusinessRegistryConfigurations/helper"
import {
   findKeyValue,
   transformToNumber,
} from "views/common/InvoiceForm/CommonInvoiceForm/components/ValidationHelpers"
import SelectAutoComplete from "views/common/SelectAutoComplete"
import {
   getItemEmpty,
   section1,
   section2,
   sectionItemsVariable,
   SectionType,
   SmartReceiptCreateFormInitialValues,
   SmartReceiptCreateFormValue,
   SmartReceiptValidationSchema,
} from "./utils"

type keyValue = keyof SmartReceiptCreateFormValue
type CreateSmartReceiptFormProps = {
   table: TableName
}
const CreateSmartReceiptForm = ({ table }: CreateSmartReceiptFormProps) => {
   const dispatch = useAppDispatch()
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const [globalError, setGlobalError] = useState<string | null>(null)

   const [createSmartReceipt] = useCreateSmartReceiptMutation()

   const [
      getBusinessRegistryConfiguration,
      { isLoading, isError: isErrorFetching },
   ] = useLazyGetBusinessRegistryConfigurationQuery()

   const { data: BusinessRegistryData, isLoading: isLoadingFiscalId } =
      useGetBusinessRegistryConfigurationsQuery({
         receipts_enabled: 1,
      })

   const itemsBusinessRegistryData = BusinessRegistryData
      ? "hydra:member" in BusinessRegistryData
         ? formatBusinessRegistryConfigurations(
              BusinessRegistryData["hydra:member"]
           )
         : formatBusinessRegistryConfigurations([BusinessRegistryData])
      : undefined

   const {
      register,
      handleSubmit: handleSubmitForm,
      setError,
      control,
      setValue,
      formState: { errors: errorsForm, isSubmitting },
      getValues,
   } = useForm<SmartReceiptCreateFormValue>({
      defaultValues: SmartReceiptCreateFormInitialValues,
      resolver: yupResolver(SmartReceiptValidationSchema) as any,
   })

   const { fields, append, remove } = useFieldArray({
      control,
      name: "items",
   })

   const handleClose = () => {
      dispatch(closeDialog())
   }

   function formatBodyPayload(data: CreateSmartReceiptBody) {
      const body = cleanedJsonObject(data)
      sections.forEach((section) => {
         if (!(section.name in body)) return
         if (section.format === "number") {
            body[section.name] = parseFloat(body[section.name])
         }
         if (section.format === "integer") {
            body[section.name] = parseInt(body[section.name])
         }
      })

      sectionItemsVariable.forEach((section) => {
         body.items.forEach((item: any) => {
            if (!item[section.name]) return
            if (section.format === "number")
               item[section.name] = parseFloat(item[section.name])
            if (section.format === "integer")
               item[section.name] = parseInt(item[section.name])
         })
      })

      return cleanedJsonObject(body)
   }

   const onSubmit: SubmitHandler<
      typeof SmartReceiptCreateFormInitialValues
   > = async (data) => {
      setGlobalError(null)

      const body = formatBodyPayload(data)

      const response = await createSmartReceipt({
         data: body,
      })

      if ("error" in response && "data" in response.error) {
         const data = response.error.data as {
            violations?: { propertyPath: string; message: string }[]
         } | null

         if ("violations" in (response.error.data as any)) {
            data?.violations?.forEach((violation) => {
               if (violation.propertyPath === "") {
                  setGlobalError(violation.message)
                  return
               }
               if (violation.propertyPath.startsWith("items")) {
                  setError(violation.propertyPath as keyValue, {
                     type: "validation Error",
                     message: violation.message,
                  })
                  return
               }
               setError(violation.propertyPath as keyValue, {
                  type: "validation Error",
                  message: violation.message,
               })
            })
            return
         }

         setGlobalError(t("smartReceipt.Errore_nella_creazione"))
         return
      }

      if ("data" in response) {
         enqueueSnackbar(t("smartReceipt.Creato_con_successo"), {
            variant: "success",
         })
         persistTableRefresh(table)
         dispatch(
            updateRefetch({
               table: table,
            })
         )
         dispatch(closeDialog())
      }
   }

   const getErrorForName = (name: string) => {
      return findKeyValue(errorsForm, name) as any
   }

   const handleChangeTextField = (
      field: SectionType,
      name: keyValue,
      value: any
   ) => {
      if (value === null || value === undefined) {
         setValue(name, value)
         return value
      }

      if (value === "") {
         const result = field.required ? "" : null
         setValue(name, result)
         return result
      }

      const numberValue = Number(value.replace(",", "."))
      if (field.format === "number" && !isNaN(numberValue)) {
         const result = transformToNumber(
            numberValue.toString(),
            field.decimalPlace
         )
         setValue(name, result)
         return result
      }
      if (field.format === "uppercase") {
         const result = value.toUpperCase()
         setValue(name, result)
         return result
      }
      setValue(name, value)
      return value
   }

   const updateOptionsFiscalIds = async (value: string) => {
      const { data } = await getBusinessRegistryConfiguration({
         fiscal_id: value,
      })

      let fiscalIds: string[] = []
      if (isErrorFetching) {
         fiscalIds = []
      }

      if (data) {
         fiscalIds = data.receipts_enabled ? [data.fiscal_id] : []
      }

      return fiscalIds
   }

   function renderInput(section: SectionType) {
      const { name, label, type, width, inputProps } = section

      switch (type) {
         case "textField":
         case "number":
            return (
               <Grid item xs={width?.xs ?? 12} md={width?.md ?? 3} key={name}>
                  <Controller
                     name={name as keyof SmartReceiptCreateFormValue}
                     control={control}
                     render={({ field: fieldInternal }) => (
                        <TextField
                           style={{
                              backgroundColor: "white",
                              borderBottomRightRadius: "5px !important",
                              borderBottomLeftRadius: "5px !important",
                           }}
                           type={type === "number" ? "number" : "text"}
                           inputProps={inputProps}
                           label={t(label)}
                           fullWidth
                           variant="outlined"
                           {...register(name as keyValue)}
                           onBlur={(e) => {
                              handleChangeTextField(
                                 section,
                                 name as keyValue,
                                 e.target.value
                              )
                           }}
                           helperText={getErrorForName(name)?.message}
                           error={getErrorForName(name) != null}
                        />
                     )}
                  />
               </Grid>
            )
         case "checkbox":
            return (
               <Grid item xs={width?.xs ?? 12} md={width?.md ?? 3} key={name}>
                  <Controller
                     name={name as keyof SmartReceiptCreateFormValue}
                     control={control}
                     render={({ field }) => (
                        <FormControl
                           error={
                              !!errorsForm[
                                 name as keyof SmartReceiptCreateFormValue
                              ]
                           }
                        >
                           <FormControlLabel
                              control={
                                 <Checkbox
                                    color="primary"
                                    {...register(name as keyValue)}
                                 />
                              }
                              label={t(label)}
                           />
                           {getErrorForName(name) && (
                              <FormHelperText>
                                 {getErrorForName(name)?.message}
                              </FormHelperText>
                           )}
                        </FormControl>
                     )}
                  />
               </Grid>
            )
         case "select":
            const options = section.options ?? []
            return (
               <Grid item xs={width?.xs ?? 12} md={width?.md ?? 3} key={name}>
                  <Controller
                     name={name as keyof SmartReceiptCreateFormValue}
                     control={control}
                     render={({ field }) => (
                        <FormControl fullWidth variant="outlined">
                           <InputLabel htmlFor={name}>{t(label)}</InputLabel>
                           <Select
                              style={{
                                 backgroundColor: "white",
                                 borderBottomRightRadius: "5px !important",
                                 borderBottomLeftRadius: "5px !important",
                              }}
                              inputProps={{
                                 name: name,
                                 id: name,
                              }}
                              fullWidth
                              //@ts-ignore
                              helperText={getErrorForName(name)?.message}
                              error={getErrorForName(name) != null}
                              {...register(name as keyValue)}
                              defaultValue={getValues(name as keyValue)}
                           >
                              {options.map((option) => (
                                 <MenuItem
                                    key={option.value}
                                    value={option.value}
                                    title={option.label}
                                 >
                                    {option.label}
                                 </MenuItem>
                              ))}
                           </Select>
                        </FormControl>
                     )}
                  />
               </Grid>
            )
         case "selectAsync":
            switch (name) {
               case "fiscal_id":
                  return (
                     <Grid
                        item
                        xs={width?.xs ?? 12}
                        md={width?.md ?? 3}
                        key={name}
                     >
                        <SelectAutoComplete
                           name={name}
                           label={t(label)}
                           initialOptions={
                              itemsBusinessRegistryData?.map((data) => ({
                                 name: data.fiscal_id,
                              })) ?? []
                           }
                           loading={isLoading || isLoadingFiscalId}
                           handleUpdateOptions={updateOptionsFiscalIds}
                           handleChange={(value) => {
                              if (
                                 value &&
                                 value.length > 0 &&
                                 value !== getValues(name as keyValue)
                              ) {
                                 setValue(name as keyValue, value)
                              }
                           }}
                           value={{
                              name: getValues(name as keyValue),
                           }}
                           //@ts-ignore
                           helperText={getErrorForName(name)?.message}
                           error={getErrorForName(name) != null}
                           required={true}
                        />
                     </Grid>
                  )
               default:
                  return null
            }
         default:
            return null
      }
   }

   function renderBlockInput(item: any, index: number) {
      return (
         <Grid item xs={12} md={12} container key={index} spacing={2}>
            {errorsForm.items && errorsForm.items[index] && (
               <Grid item xs={12} md={12}>
                  <Alert
                     severity="error"
                     style={{
                        borderRadius: "5px",
                     }}
                  >
                     {Object.entries(errorsForm.items[index] as any).map(
                        ([key, value], idx) => {
                           return (
                              <Box
                                 style={{
                                    borderRadius: "5px",
                                 }}
                                 mt={idx > 1 ? 1 : 0}
                                 key={key}
                              >
                                 {key}:{" "}
                                 {(value as any).message ??
                                    (value as any)?.map(
                                       (
                                          v:
                                             | { [s: string]: unknown }
                                             | ArrayLike<unknown>,
                                          idx: number
                                       ) => (
                                          <Box>
                                             - {idx + 1}:
                                             {v &&
                                                Object.entries(v).map(
                                                   ([k, v]) => (
                                                      <Box key={k}>
                                                         {k}:{" "}
                                                         {(v as any).message}
                                                      </Box>
                                                   )
                                                )}
                                          </Box>
                                       )
                                    )}
                              </Box>
                           )
                        }
                     )}
                  </Alert>
               </Grid>
            )}
            <Grid item xs={12} md={12} alignItems="flex-end">
               <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography
                     variant="h6"
                     style={{
                        color: "black",
                        fontWeight: "bold",
                     }}
                  >
                     {t("smartReceipt.item")} {index + 1}
                  </Typography>

                  {fields.length > 1 && (
                     <Button
                        onClick={() => remove(index)}
                        variant="contained"
                        style={{
                           background: "#920000",
                           color: "white",
                           fontWeight: "bold",
                        }}
                     >
                        {t("smartReceipt.Rimuovi_item")}
                     </Button>
                  )}
               </Box>
            </Grid>
            {sectionItemsVariable.map((section) => {
               const { name, label, type, width } = section
               return renderInput({
                  ...section,
                  name: `items[${index}].${name}`,
                  label,
                  type,
                  width,
               })
            })}
         </Grid>
      )
   }

   const sections = [...section1, ...section2]

   const isErrors =
      globalError !== null ||
      (Object.entries(errorsForm).length > 0 &&
         Object.entries(errorsForm as any).some(
            ([key, value]) => key.length === 0 && "message" in (value as any)
         ))

   return (
      <Grid container>
         <Grid item xs={12} md={12}>
            {isErrors && (
               <>
                  <Box mt={2} mb={2}>
                     <Alert severity="error">
                        <AlertTitle>
                           {t("smartReceipt.Errore_nella_creazione")}
                        </AlertTitle>
                        <p>{globalError}</p>

                        {Object.entries(errorsForm).length > 0 &&
                           Object.entries(errorsForm as any).map(
                              ([key, value], idx) => {
                                 return (
                                    <Box
                                       style={{
                                          borderRadius: "5px",
                                       }}
                                       mt={idx > 1 ? 1 : 0}
                                       key={key}
                                    >
                                       {"message" in (value as any) &&
                                          key.length === 0 && (
                                             <p>
                                                {key}: {(value as any).message}
                                             </p>
                                          )}
                                    </Box>
                                 )
                              }
                           )}
                     </Alert>
                  </Box>
               </>
            )}
         </Grid>
         <Grid item container alignItems={"flex-start"} spacing={1}>
            {section1.map((item) => renderInput(item))}
            <Grid
               item
               xs={12}
               md={12}
               container
               spacing={2}
               style={{
                  marginBottom: "5px",
               }}
               key={"items"}
            >
               <Grid container item xs={12} md={12} alignItems={"flex-start"}>
                  {fields.map((item, index) => (
                     <Grid
                        item
                        xs={12}
                        md={12}
                        key={item.id}
                        style={{
                           border: "1px solid #ccc",
                           padding: "10px",
                           marginBottom:
                              fields.length - 1 === index ? 0 : "10px",
                           backgroundColor:
                              index % 2 === 0 ? "#f9f9f9" : "#fff",
                        }}
                     >
                        {renderBlockInput(item, index)}
                     </Grid>
                  ))}
               </Grid>
               <Grid item xs={12} md={12} alignItems={"flex-start"}>
                  <Button
                     onClick={() => append(getItemEmpty())}
                     variant="contained"
                     color="primary"
                  >
                     {t("smartReceipt.Aggiungi_item")}
                  </Button>
               </Grid>
            </Grid>
            {section2.map((item) => renderInput(item))}
         </Grid>
         <Box
            width="100%"
            style={{
               position: "sticky",
               bottom: 0,
               background: "#fff",
               padding: "20px",
               zIndex: 1000,
            }}
         >
            <Grid container justifyContent="center">
               <Button
                  color={isSubmitting ? "default" : "primary"}
                  variant="contained"
                  size="medium"
                  onClick={handleSubmitForm(onSubmit)}
               >
                  {t("Default.Salva")}
               </Button>

               <Button
                  onClick={handleClose}
                  color="default"
                  variant="contained"
                  style={{ marginLeft: "10px" }}
               >
                  {t("Default.Annulla")}
               </Button>
            </Grid>
         </Box>
      </Grid>
   )
}

export default CreateSmartReceiptForm
