import { Dialog, DialogProps as MaterialDialogProps } from "@material-ui/core"
import { ReactNode } from "react"
import { useAppDispatch, useAppSelector } from "store"
import {
   cancel,
   closeDialog,
   DialogId,
   DialogState,
   selectActiveDialog,
} from "store/slices/dialogs"

type DialogProps<Data, Actions> = Data extends null ? Actions : Data & Actions

export type BaseDialogActions = {
   handleClose: VoidFunction
}

type Props<Id> = Pick<MaterialDialogProps, "fullWidth" | "maxWidth"> & {
   id: Id
   children: (
      data: DialogProps<
         Extract<NonNullable<DialogState>, { id: Id }>["data"],
         BaseDialogActions
      >
   ) => ReactNode
}

/**
 * Given a dialog id mounts/unmounts the dialog based on the state in the redux store,
 * and pass the dialog state down to the children.
 */
const BaseDialog = <Id extends DialogId>({
   id,
   children,
   ...dialogProps
}: Props<Id>) => {
   const dispatch = useAppDispatch()
   const activeDialog = useAppSelector(selectActiveDialog)
   const isDialogOpen = activeDialog?.id === id

   const handleClose = () => {
      if (id === "confirmation_dialog") {
         dispatch(cancel())
      }

      dispatch(closeDialog())
   }

   if (!isDialogOpen) {
      return null
   }

   return (
      <Dialog open={isDialogOpen} onClose={handleClose} {...dialogProps}>
         {/* We don't care about the `any` cast here, the right type comes from the props. */}
         {children({ handleClose, ...(activeDialog!.data as any) })}
      </Dialog>
   )
}

export default BaseDialog
