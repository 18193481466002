import { Button, CircularProgress } from "@material-ui/core"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useConnectMutation } from "services/openbankingApi"
import { useAppDispatch } from "store"
import { openDialog } from "store/slices/dialogs"

type Props = {
   fiscalId: string
}

const ConnectCTA = ({ fiscalId }: Props) => {
   const dispatch = useAppDispatch()
   const { t } = useTranslation()
   const { enqueueSnackbar } = useSnackbar()
   const [triggerConnect, { isLoading }] = useConnectMutation()

   const connect = async () => {
      const response = await triggerConnect({
         fiscalId,
         locale: null,
         country: null,
      })

      if ("data" in response) {
         const { connectUrl } = response.data

         dispatch(
            openDialog({
               id: "openbanking_connect_success",
               data: { connectUrl },
            })
         )

         return
      }

      enqueueSnackbar(t("global.messages.generic_error"), { variant: "error" })
   }

   return (
      <Button
         disabled={isLoading}
         color="primary"
         variant="contained"
         onClick={connect}
      >
         {!isLoading && t("openbanking.business_registry.connect_cta")}
         {isLoading && <CircularProgress color="inherit" size={22} />}
      </Button>
   )
}

export default ConnectCTA
