import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import { makeStyles } from "@material-ui/core/styles"
import { useTranslation } from "react-i18next"
import { Notification } from "services/gov-it/types"
import LabelNotification from "views/common/LabelNotification"

const useStyles = makeStyles((theme) => ({
   root: {
      width: "100%",
   },
}))

type Props = {
   notification: Notification
}
export function RCNotificationDetails({ notification }: Props) {
   const classes = useStyles()
   const { t } = useTranslation()

   return (
      <List className={classes.root}>
         <ListItem>
            <ListItemText primary="UUID" />
            <ListItemSecondaryAction>
               <ListItemText primary={notification.uuid} />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem>
            <ListItemText primary={t("CustomerInvoices.Tipo")} />
            <ListItemSecondaryAction>
               <ListItemText
                  primary={<LabelNotification value={notification.type} />}
               />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem>
            <ListItemText primary={t("CustomerInvoices.Data ora consegna")} />
            <ListItemSecondaryAction>
               <ListItemText primary={notification.message.data_ora_consegna} />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem>
            <ListItemText primary={t("CustomerInvoices.Data ora ricezione")} />
            <ListItemSecondaryAction>
               <ListItemText
                  primary={notification.message.data_ora_ricezione}
               />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem>
            <ListItemText primary={t("CustomerInvoices.Destinatario codice")} />
            <ListItemSecondaryAction>
               <ListItemText
                  primary={notification.message?.destinatario?.codice}
               />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem>
            <ListItemText primary={t("CustomerInvoices.Identificativo SDI")} />
            <ListItemSecondaryAction>
               <ListItemText
                  primary={notification.message.identificativo_sdi}
               />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem>
            <ListItemText primary={t("CustomerInvoices.Nome file")} />
            <ListItemSecondaryAction>
               <ListItemText primary={notification.message.nome_file} />
            </ListItemSecondaryAction>
         </ListItem>

         <ListItem>
            <ListItemText
               primary={t("CustomerInvoices.Destinatario descrizione")}
               secondary={notification.message.destinatario.descrizione}
            />
         </ListItem>

         <ListItem>
            <ListItemText
               primary={t("CustomerInvoices.Note")}
               secondary={notification.message.note}
            />
         </ListItem>
      </List>
   )
}
