import { Grid, makeStyles } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import { Field, Formik, FormikHelpers } from "formik"
import { Select, TextField } from "formik-material-ui"
import { useTranslation } from "react-i18next"
import { ApiConfiguration } from "services/gov-it/apiConfigurations/types"

type Props = {
   apiConfiguration?: ApiConfiguration
   handleClose: () => void
   handleSaveApiConfiguration: (
      uuid: string | null,
      values: FormValues,
      helpers: FormikHelpers<FormValues>
   ) => void
   isLoading: boolean
}

export type FormValues = {
   authentication_key: string | null
   authentication_token: string | null
   authentication_type: string | null
   event: string | null
   target_url: string | null
   uuid: string | null
}

const initialValues: FormValues = {
   authentication_key: null,
   authentication_token: null,
   authentication_type: null,
   event: null,
   target_url: null,
   uuid: null,
}

function ApiConfigurationForm({
   apiConfiguration,
   handleClose,
   handleSaveApiConfiguration,
   isLoading,
}: Props) {
   const classes = useStyles()
   const { t } = useTranslation()

   const handleSubmit = async (
      values: FormValues,
      helpers: FormikHelpers<FormValues>
   ) => {
      await handleSaveApiConfiguration(
         apiConfiguration?.uuid ?? null,
         values,
         helpers
      )
   }

   const initialFormValues = (apiConfiguration as FormValues) ?? initialValues

   return (
      <Formik initialValues={initialFormValues} onSubmit={handleSubmit}>
         {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            resetForm,
         }) => (
            <form onSubmit={handleSubmit} noValidate>
               <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                     <FormControl fullWidth>
                        <InputLabel>{t("Integrazione.Evento")}</InputLabel>
                        <Field
                           component={Select}
                           name="event"
                           error={Boolean(touched.event && errors.event)}
                           onBlur={handleBlur}
                        >
                           <MenuItem value={undefined}>None</MenuItem>
                           <MenuItem value={"supplier-invoice"}>
                              [supplier-invoice]{" "}
                              {t("Integrazione.supplier-invoice event label")}
                           </MenuItem>
                           <MenuItem value={"customer-invoice"}>
                              [customer-invoice]{" "}
                              {t("Integrazione.customer-invoice event label")}
                           </MenuItem>
                           <MenuItem value={"customer-notification"}>
                              [customer-notification]{" "}
                              {t(
                                 "Integrazione.customer-notification event label"
                              )}
                           </MenuItem>
                           <MenuItem value={"invoice-status-quarantena"}>
                              [invoice-status-quarantena]{" "}
                              {t(
                                 "Integrazione.invoice-status-quarantena event label"
                              )}
                           </MenuItem>
                           <MenuItem value={"invoice-status-invoice-error"}>
                              [invoice-status-invoice-error]{" "}
                              {t(
                                 "Integrazione.invoice-status-invoice-error event label"
                              )}
                           </MenuItem>
                           <MenuItem value={"legal-storage-missing-vat"}>
                              [legal-storage-missing-vat]{" "}
                              {t(
                                 "Integrazione.legal-storage-missing-vat event label"
                              )}
                           </MenuItem>
                           <MenuItem value={"legal-storage-receipt"}>
                              [legal-storage-receipt]{" "}
                              {t(
                                 "Integrazione.legal-storage-receipt event label"
                              )}
                           </MenuItem>
                        </Field>
                     </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                     <Field
                        component={TextField}
                        type="target_url"
                        label={t("Integrazione.Url")}
                        name="target_url"
                        error={Boolean(touched.target_url && errors.target_url)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                     />
                  </Grid>

                  <Grid item md={12} xs={12}>
                     <FormControl fullWidth>
                        <InputLabel htmlFor="authentication_type">
                           {t("Integrazione.Authentication type")}
                        </InputLabel>
                        <Field
                           component={Select}
                           name="authentication_type"
                           error={Boolean(
                              touched.authentication_type &&
                                 errors.authentication_type
                           )}
                           onBlur={handleBlur}
                           fullWidth
                        >
                           <MenuItem value={undefined}>None</MenuItem>
                           <MenuItem value={"query"}>Query</MenuItem>
                           <MenuItem value={"header"}>Header</MenuItem>
                        </Field>
                     </FormControl>
                  </Grid>

                  <Grid item md={12} xs={12}>
                     <Field
                        component={TextField}
                        type="authentication_key"
                        label={t("Integrazione.Authentication key")}
                        name="authentication_key"
                        error={Boolean(
                           touched.authentication_key &&
                              errors.authentication_key
                        )}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                     />
                  </Grid>

                  <Grid item md={12} xs={12}>
                     <Field
                        component={TextField}
                        type="authentication_token"
                        label={t("Integrazione.Authentication token")}
                        name="authentication_token"
                        error={Boolean(
                           touched.authentication_token &&
                              errors.authentication_token
                        )}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                     />
                  </Grid>
               </Grid>

               <div className={classes.buttons}>
                  <Button
                     style={{ width: "max-content" }}
                     type="submit"
                     color="primary"
                     variant="contained"
                     disabled={isLoading}
                  >
                     {t("Default.Salva")}
                  </Button>
                  <Button
                     style={{ width: "max-content" }}
                     onClick={handleClose}
                     color="default"
                     variant="contained"
                  >
                     {t("Default.Annulla")}
                  </Button>
               </div>
            </form>
         )}
      </Formik>
   )
}

export default ApiConfigurationForm

const useStyles = makeStyles((theme) => ({
   buttons: {
      display: "flex",
      alignItems: "center",
      marginTop: 24,
      justifyContent: "flex-end",
      flex: "0 0 auto",
      "& > :not(:first-child)": {
         marginLeft: 8,
      },
   },
}))
