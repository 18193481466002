import { GridSize, InputBaseComponentProps } from "@material-ui/core"
import { User } from "services/commonApi"
import { InvoiceType } from "services/gov-it"
import {
   CedentePrestatoreInvoiceSimplifiedPayload,
   CedentePrestratoreInvoicePayload,
   DataDdtType,
   DatiSalType,
   RelatedDocumentData,
} from "services/gov-it/types"

export type Field = {
   name: string
   format?: "number" | "uppercase"
   labelTranslationId: string
   disabled?: true
   width?: {
      xs?: GridSize
      sm?: GridSize
      md: GridSize
      lg?: GridSize
   }
   inputProps?: InputBaseComponentProps
   required?: true
   canBeNull?: true
   autoDecrement?: true
} & (
   | { type: "textfield" }
   | { type: "arrayTextfield" }
   | { type: "date" }
   | {
        type: "select"
        default?: {
           label: string
           value: string
        }
        options: {
           label: string
           value: string
        }[]
     }
   | {
        type: "arrayObject"
        children: Field[]
     }
)

export type FieldGroup = {
   key: string
   sectionTranslationId: string
   fields: Field[]
   isChildVariable?: true
   children?: FieldGroup[]
}

export type FormInvoiceType = InvoiceType

export type FieldGroupByNameKey<T extends FormInvoiceType> = T extends "simple"
   ?
        | "dati_ddt"
        | "dati_sal"
        | "dati_convenzione"
        | "dati_ricezione"
        | "dati_contratto"
        | "dati_fatture_collegate"
        | "dettaglio_linee"
        | "dati_ordine_acquisto"
        | "dati_pagamento"
        | "dettaglio_pagamento"
        | "dati_beni_servizi"
        | "dati_riepilogo"
   : "dati_beni_servizi"

export type SectionKeyType<T extends FormInvoiceType> = T extends "simple"
   ?
        | "dati_ddt"
        | "dati_ordine_acquisto"
        | "dettaglio_linee"
        | "dati_riepilogo"
        | "dati_pagamento"
        | "dati_contratto"
        | "dati_ricezione"
        | "dati_fatture_collegate"
        | "dati_sal"
        | "dati_convenzione"
   : "dati_beni_servizi"

// select options
const naturaOptions = [
   {
      label: "N1 - Escluse ex. art. 15 del D.P.R. 633/1972",
      value: "N1",
   },
   {
      label: "N2 - Non soggette",
      value: "N2",
   },
   {
      label: "N2.1 - Non soggette ad IVA ai sensi degli artt. da 7 a 7-septies del DPR 633/72",
      value: "N2.1",
   },
   {
      label: "N2.2 - Non soggette - altri casi",
      value: "N2.2",
   },
   {
      label: "N3 - Non imponibili",
      value: "N3",
   },
   {
      label: "N3.1 - Non imponibili - esportazioni",
      value: "N3.1",
   },
   {
      label: "N3.2 - Non imponibili - cessioni intracomunitarie",
      value: "N3.2",
   },
   {
      label: "N3.3 - Non imponibili - cessioni verso San Marino",
      value: "N3.3",
   },
   {
      label: "N3.4 - Non imponibili - operazioni assimilate alle cessioni all’esportazione",
      value: "N3.4",
   },
   {
      label: "N3.5 - Non imponibili - a seguito di dichiarazioni d’intento",
      value: "N3.5",
   },
   {
      label: "N3.6 - Non imponibili - altre operazioni che non concorrono alla formazione del plafond",
      value: "N3.6",
   },
   {
      label: "N4 - Esenti",
      value: "N4",
   },
   {
      label: "N5 - Regime del margine / IVA non esposta in fattura",
      value: "N5",
   },
   {
      label: "N6 - Inversione contabile (per le operazioni in reverse charge ovvero nei casi di autofatturazione per acquisti extra UE di servizi ovvero per importazioni di beni nei soli casi previsti)",
      value: "N6",
   },
   {
      label: "N6.1 - Inversione contabile - cessione di rottami e altri materiali di recupero",
      value: "N6.1",
   },
   {
      label: "N6.2 - Inversione contabile - cessione di oro e argento ai sensi della legge 7/2000 nonché di oreficeria usata ad OPO",
      value: "N6.2",
   },
   {
      label: "N6.3 - Inversione contabile - subappalto nel settore edile",
      value: "N6.3",
   },
   {
      label: "N6.4 - Inversione contabile - cessione di fabbricati",
      value: "N6.4",
   },
   {
      label: "N6.5 - Inversione contabile - cessione di telefoni cellulari",
      value: "N6.5",
   },
   {
      label: "N6.6 - Inversione contabile - cessione di prodotti elettronici",
      value: "N6.6",
   },
   {
      label: "N6.7 - Inversione contabile - prestazioni comparto edile e settori connessi",
      value: "N6.7",
   },
   {
      label: "N6.8 - Inversione contabile - operazioni settore energetico",
      value: "N6.8",
   },
   {
      label: "N6.9 - Inversione contabile - altri casi",
      value: "N6.9",
   },
   {
      label: "N7 - IVA assolta in altro stato UE (prestazione di servizi di telecomunicazioni, tele-radiodiffusione ed elettronici ex art. 7-octies lett. a, b, art. 74-sexies DPR 633/72)",
      value: "N7",
   },
]
const tipoDocumentoSimpleOptions = [
   { label: "TD01 - Fattura", value: "TD01" },
   { label: "TD02 - Acconto / anticipo su fattura", value: "TD02" },
   { label: "TD03 - Acconto / anticipo su parcella", value: "TD03" },
   { label: "TD04 - Nota di credito", value: "TD04" },
   { label: "TD05 - Nota di debito", value: "TD05" },
   { label: "TD06 - Parcella", value: "TD06" },
   {
      label: "TD16 - Integrazione fattura reverse charge interno",
      value: "TD16",
   },
   {
      label: "TD17 - Integrazione/autofattura per acquisto servizi dall'estero",
      value: "TD17",
   },
   {
      label: "TD18 - Integrazione per acquisto di beni intracomunitari",
      value: "TD18",
   },
   {
      label: "TD19 - Integrazione/autofattura per acquisto di beni ex art.17 c.2 DPR 633/72",
      value: "TD19",
   },
   {
      label: "TD20 - Autofattura per regolarizzazione e integrazione delle fatture (ex art.6 c.8 e 9-bis d.lgs.471/97 o art.46 c.5 D.L. 331/93",
      value: "TD20",
   },
   { label: "TD21 - Autofattura per splafonamento", value: "TD21" },
   { label: "TD22 - Estrazione benida Deposito IVA", value: "TD22" },
   {
      label: "TD23 - Estrazione beni da Deposito IVA con versamento dell'IVA",
      value: "TD23",
   },
   {
      label: "TD24 - Fattura differita di cui all'art.21, comma 4, terzo periodo lett. a) DPR 633/72",
      value: "TD24",
   },
   {
      label: "TD25 - Fattura differita di cui all'art.21, comma 4, terzo periodo lett. b) DPR 633/72",
      value: "TD25",
   },
   {
      label: "TD26 - Cessione di beni ammortizzabili e per passaggi interni (ex art.36 DPR 633/72)",
      value: "TD26",
   },
   {
      label: "TD27 - Fattura per autoconsumo o per cessioni gratuite senza rivalsa",
      value: "TD27",
   },
   {
      label: "TD28 - Acquisti da San Marino con IVA (fattura cartacea)",
      value: "TD28",
   },
]
const tipoDocumentoSimplifiedOptions = [
   { label: "TD07 - Fattura semplificata", value: "TD07" },
   { label: "TD08 - Nota di credito semplificata", value: "TD08" },
   { label: "TD09 - Nota di debito semplificata", value: "TD09" },
]
const regisFiscaliOptions = [
   {
      label: "RF01 - Ordinario",
      value: "RF01",
   },
   {
      label: "RF02 - Contribuenti minimi (art.1, c.96-117, L. 244/07)",
      value: "RF02",
   },
   {
      label: "RF04 - Agricoltura e attività connesse e pesca (artt.34 e 34-bis DPR 633/72)",
      value: "RF04",
   },
   {
      label: "RF05 - Vendita sali e tabacchi (art.74, c.1, DPR 633/72)",
      value: "RF05",
   },
   {
      label: "RF06 - Commercio fiammiferi (art.74, c.1, DPR 633/72)",
      value: "RF06",
   },
   {
      label: "RF07 - Editoria (art.74, c.1, DPR 633/72)",
      value: "RF07",
   },
   {
      label: "RF08 - Gestione servizi telefonia (art.74, c.1, DPR 633/72)",
      value: "RF08",
   },
   {
      label: "RF09 - Rivendita documenti di trasporto pubblico e di sosta (art.74, c.1, DPR 633/72)",
      value: "RF09",
   },
   {
      label: "RF10 - Intrattenimenti, giochi e altre attività di cui alla tariffa allegata al DPR 640/72 (art.74, c.6, DPR 633/72)",
      value: "RF10",
   },
   {
      label: "RF11 - Agenzie viaggi e turismo (art.74-ter, DPR 633/72)",
      value: "RF11",
   },
   {
      label: "RF12 - Agriturismo (art.5, c.2, L. 413/91)",
      value: "RF12",
   },
   {
      label: "RF13 - Vendite a domicilio (art.25-bis, c.6, DPR 600/73)",
      value: "RF13",
   },
   {
      label: "RF14 - Rivendita beni usati, di oggetti d’arte, d’antiquariato o da collezione (art.36, DL 41/95)",
      value: "RF14",
   },
   {
      label: "RF15 - Agenzie di vendite all’asta di oggetti d’arte, antiquariato o da collezione (art.40-bis, DL 41/95)",
      value: "RF15",
   },
   {
      label: "RF16 - IVA per cassa P.A. (art.6, c.5, DPR 633/72)",
      value: "RF16",
   },
   {
      label: "RF17 - IVA per cassa (art.32-bis, DL 83/2012)",
      value: "RF17",
   },
   {
      label: "RF18 - Altro",
      value: "RF18",
   },
   {
      label: "RF19 - Regime forfettario (art.1, c.54-89, L. 190/2014)",
      value: "RF19",
   },
]
const currencyOptions = [
   { label: "USD", value: "USD" },
   { label: "EUR", value: "EUR" },
   { label: "JPY", value: "JPY" },
   { label: "GBP", value: "GBP" },
   { label: "AUD", value: "AUD" },
   { label: "CAD", value: "CAD" },
   { label: "CHF", value: "CHF" },
   { label: "CNY", value: "CNY" },
   { label: "SEK", value: "SEK" },
   { label: "NZD", value: "NZD" },
   { label: "MXN", value: "MXN" },
   { label: "SGD", value: "SGD" },
   { label: "HKD", value: "HKD" },
   { label: "NOK", value: "NOK" },
   { label: "KRW", value: "KRW" },
   { label: "TRY", value: "TRY" },
   { label: "RUB", value: "RUB" },
   { label: "INR", value: "INR" },
   { label: "BRL", value: "BRL" },
   { label: "ZAR", value: "ZAR" },
]
const modalitaPagamentoOptions = [
   {
      label: "MP01 - Contanti",
      value: "MP01",
   },
   {
      label: "MP02 - Assegno",
      value: "MP02",
   },
   {
      label: "MP03 - Assegno circolare",
      value: "MP03",
   },
   {
      label: "MP04 - Contanti presso Tesoreria",
      value: "MP04",
   },
   {
      label: "MP05 - Bonifico",
      value: "MP05",
   },
   {
      label: "MP06 - Vaglia cambiario",
      value: "MP06",
   },
   {
      label: "MP07 - Bollettino bancario",
      value: "MP07",
   },
   {
      label: "MP08 - Carta di pagamento",
      value: "MP08",
   },
   {
      label: "MP09 - RID",
      value: "MP09",
   },
   {
      label: "MP10 - RID utenze",
      value: "MP10",
   },
   {
      label: "MP11 - RID veloce",
      value: "MP11",
   },
   {
      label: "MP12 - RIBA",
      value: "MP12",
   },
   {
      label: "MP13 - MAV",
      value: "MP13",
   },
   {
      label: "MP14 - Quietanza erario",
      value: "MP14",
   },
   {
      label: "MP15 - Giroconto su conti di contabilità speciale",
      value: "MP15",
   },
   {
      label: "MP16 - Domiciliazione bancaria",
      value: "MP16",
   },
   {
      label: "MP17 - Domiciliazione postale",
      value: "MP17",
   },
   {
      label: "MP18 - Bollettino di c/c postale",
      value: "MP18",
   },
   {
      label: "MP19 - SEPA Direct Debit",
      value: "MP19",
   },
   {
      label: "MP20 - SEPA Direct Debit CORE",
      value: "MP20",
   },
   {
      label: "MP21 - SEPA Direct Debit B2B",
      value: "MP21",
   },
   {
      label: "MP22 - Trattenuta su somme già riscosse",
      value: "MP22",
   },
   {
      label: "MP23 - PAGOPA",
      value: "MP23",
   },
]

export type FieldGroupByName<T extends FormInvoiceType> = {
   key: FieldGroupByNameKey<T>
   name: string
   sectionTranslationId: string
   sections: FieldGroup[]
   initialExist: boolean
   childrenListNames?: {
      key: FieldGroupByNameKey<T>
      sectionTranslationId: string
   }[]
}

const getFieldsArrayDatiGeneraliBySectionKey = (
   key: string,
   sectionTranslationId: string,
   sectionKey: FieldGroupByNameKey<"simple">,
   index: number
): FieldGroup => {
   switch (sectionKey) {
      case "dati_sal":
         return {
            key,
            sectionTranslationId,
            fields: [
               {
                  type: "textfield",
                  name: `fattura_elettronica_body[0].dati_generali.${sectionKey}[${index}].riferimento_fase`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.riferimento_fase",
                  required: true,
                  width: {
                     md: 12,
                  },
               },
            ],
         }
      default:
         return {
            key,
            sectionTranslationId: sectionTranslationId,
            fields: [
               {
                  type: "textfield",
                  name: `fattura_elettronica_body[0].dati_generali.${sectionKey}[${index}].id_documento`,
                  labelTranslationId: "gov_it.invoice_fix.fields.id_documento",
                  required: true,
                  width: {
                     md: 12,
                  },
               },
               {
                  type: "date",
                  name: `fattura_elettronica_body[0].dati_generali.${sectionKey}[${index}].data`,
                  labelTranslationId: "gov_it.invoice_fix.fields.data",
                  width: {
                     md: 3,
                  },
               },
               {
                  type: "textfield",
                  name: `fattura_elettronica_body[0].dati_generali.${sectionKey}[${index}].codice_cup`,
                  labelTranslationId: "gov_it.invoice_fix.fields.codice_cup",
                  width: {
                     md: 3,
                  },
               },
               {
                  type: "textfield",
                  name: `fattura_elettronica_body[0].dati_generali.${sectionKey}[${index}].codice_cig`,
                  labelTranslationId: "gov_it.invoice_fix.fields.codice_cig",
                  width: {
                     md: 3,
                  },
               },
               {
                  type: "textfield",
                  name: `fattura_elettronica_body[0].dati_generali.${sectionKey}[${index}].codice_commessa_convenzione`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.codice_commessa_convenzione",
                  width: {
                     md: 3,
                  },
               },
               {
                  type: "arrayTextfield",
                  name: `fattura_elettronica_body[0].dati_generali.${sectionKey}[${index}].riferimento_numero_linea`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.riferimento_numero_linea",
                  width: {
                     md: 12,
                  },
               },
            ],
         }
   }
}

const getFieldsArrayDatiPagamentoBySectionKey = (
   key: string,
   sectionKey: "dati_pagamento",
   index: number
): FieldGroup => {
   switch (sectionKey) {
      case "dati_pagamento":
         return {
            key,
            sectionTranslationId: `gov_it.invoice_fix.sections.dati_pagamento`,
            fields: [
               {
                  type: "select",
                  required: true,
                  options: [
                     {
                        label: "TP01 - Pagamento a rate",
                        value: "TP01",
                     },
                     {
                        label: "TP02 - Pagamento completo",
                        value: "TP02",
                     },
                     {
                        label: "TP03 - Anticipo",
                        value: "TP03",
                     },
                  ],
                  name: `fattura_elettronica_body[0].dati_pagamento[${index}].condizioni_pagamento`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.dati_pagamento_condizioni",
                  width: {
                     md: 12,
                  },
               },
            ],
            children: [],
         }
   }
}

const getEmptyDataDdtFields = (): DataDdtType => {
   return {
      numero_ddt: "",
      data_ddt: new Date().toISOString().split("T")[0],
      riferimento_numero_linea: [],
   }
}
const getEmptyRelatedDocumentFields = (): RelatedDocumentData => {
   return {
      id_documento: "",
      data: "",
      codice_cup: "",
      codice_cig: "",
      codice_commessa_convenzione: "",
      riferimento_numero_linea: [],
      num_item: "",
   }
}

export const getEmptyFieldsByFieldGroupSectionKey = (
   key: FieldGroupByNameKey<"simple">,
   index?: number
) => {
   switch (key) {
      case "dati_contratto":
      case "dati_convenzione":
      case "dati_fatture_collegate":
      case "dati_ordine_acquisto":
      case "dati_ricezione":
         return getEmptyRelatedDocumentFields()
      case "dati_ddt":
         return getEmptyDataDdtFields()
      case "dati_sal":
         return {
            riferimento_fase: 0,
         } as DatiSalType
      case "dati_pagamento":
         return {
            condizioni_pagamento: "TP01",
            dettaglio_pagamento: [
               {
                  modalita_pagamento: "MP01",
                  importo_pagamento: "",
                  data_riferimento_termini_pagamento: null,
                  data_scadenza_pagamento: null,
                  giorni_termini_pagamento: "",
                  beneficiario: "",
                  iban: "",
                  abi: "",
                  cab: "",
                  bic: "",
                  codice_pagamento: "",
               },
            ],
         }
      case "dettaglio_pagamento":
         return {
            modalita_pagamento: "MP01",
            importo_pagamento: "",
            data_riferimento_termini_pagamento: null,
            data_scadenza_pagamento: null,
            giorni_termini_pagamento: "",
            beneficiario: "",
            iban: "",
            abi: "",
            cab: "",
            bic: "",
            codice_pagamento: "",
         }
      case "dettaglio_linee":
         return {
            numero_linea: index ?? 1,
            tipo_cessione_prestazione: null,
            codice_articolo: null,
            descrizione: "",
            quantita: "",
            unita_misura: "",
            data_inizio_periodo: null,
            data_fine_periodo: null,
            prezzo_unitario: "",
            sconto_maggiorazione: null,
            prezzo_totale: "",
            aliquota_iva: "",
            ritenuta: null,
            natura: null,
            riferimento_amministrazione: null,
            altri_dati_gestionali: null,
         }
   }
}

export const AutoDecrementFieldGroup: string[] = [
   "fattura_elettronica_body[0].dati_beni_servizi.dettaglio_linee",
]

const getFieldGroupsBySectionKeyToSimpleForm = (
   section: SectionKeyType<"simple">,
   key: string,
   index: number
): FieldGroup => {
   switch (section) {
      case "dati_ddt":
         return {
            key,
            sectionTranslationId: "gov_it.invoice_fix.sections.dati_ddt",
            fields: [
               {
                  type: "textfield",
                  name: `fattura_elettronica_body[0].dati_generali.dati_ddt[${index}].numero_ddt`,
                  labelTranslationId: "gov_it.invoice_fix.fields.numero_ddt",
                  required: true,
                  width: {
                     md: 6,
                  },
               },
               {
                  type: "date",
                  name: `fattura_elettronica_body[0].dati_generali.dati_ddt[${index}].data_ddt`,
                  labelTranslationId: "gov_it.invoice_fix.fields.data_ddt",
                  required: true,
                  width: {
                     md: 6,
                  },
               },
               {
                  type: "arrayTextfield",
                  name: `fattura_elettronica_body[0].dati_generali.dati_ddt[${index}].riferimento_numero_linea`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.riferimento_numero_linea",
                  width: {
                     md: 12,
                  },
               },
            ],
         }
      case "dettaglio_linee":
         return {
            key,
            sectionTranslationId: "gov_it.invoice_fix.sections.dettaglio_linea",
            fields: [
               {
                  type: "textfield",
                  required: true,
                  name: `fattura_elettronica_body[0].dati_beni_servizi.dettaglio_linee[${index}].numero_linea`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.dettaglio_linee_numero_linea",
                  width: {
                     md: 2,
                  },
                  disabled: true,
                  autoDecrement: true,
               },
               {
                  type: "textfield",
                  required: true,
                  name: `fattura_elettronica_body[0].dati_beni_servizi.dettaglio_linee[${index}].descrizione`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.dettaglio_linee_descrizione",
                  width: {
                     md: 10,
                  },
               },
               {
                  type: "textfield",
                  required: true,
                  format: "number",
                  name: `fattura_elettronica_body[0].dati_beni_servizi.dettaglio_linee[${index}].prezzo_unitario`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.dettaglio_linee_prezzo_unitario",
                  width: {
                     md: 3,
                  },
               },
               {
                  type: "textfield",
                  required: true,
                  format: "number",
                  name: `fattura_elettronica_body[0].dati_beni_servizi.dettaglio_linee[${index}].prezzo_totale`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.dettaglio_linee_prezzo_totale",
                  width: {
                     md: 3,
                  },
               },
               {
                  type: "textfield",
                  format: "number",
                  required: true,
                  name: `fattura_elettronica_body[0].dati_beni_servizi.dettaglio_linee[${index}].aliquota_iva`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.dettaglio_linee_aliquota_iva",
                  width: {
                     md: 3,
                  },
               },
               {
                  type: "textfield",
                  required: true,
                  format: "number",
                  name: `fattura_elettronica_body[0].dati_beni_servizi.dettaglio_linee[${index}].quantita`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.dettaglio_linee_quantita",
                  width: {
                     md: 3,
                  },
               },
               {
                  name: `fattura_elettronica_body[0].dati_beni_servizi.dettaglio_linee[${index}].natura`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.dettaglio_linee_natura",
                  width: {
                     md: 12,
                  },
                  type: "select",
                  options: naturaOptions,
               },
               {
                  type: "arrayObject",
                  name: `fattura_elettronica_body[0].dati_beni_servizi.dettaglio_linee[${index}].altri_dati_gestionali`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.altri_dati_gestionali",
                  width: {
                     md: 12,
                  },
                  children: [
                     {
                        type: "textfield",
                        name: `tipo_dato`,
                        labelTranslationId:
                           "gov_it.invoice_fix.fields.tipo_dato",
                        width: {
                           md: 3,
                        },
                     },
                     {
                        type: "textfield",
                        name: `riferimento_testo`,
                        labelTranslationId:
                           "gov_it.invoice_fix.fields.riferimento_testo",
                        width: {
                           md: 9,
                        },
                     },
                  ],
               },
               /*{
                  type: "date",
                  name: `fattura_elettronica_body[0].dati_beni_servizi.dettaglio_linee[${index}].data_inizio_periodo`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.data_inizio_periodo",
                  width: {
                     md: 4,
                  },
               },
               {
                  type: "date",
                  name: `fattura_elettronica_body[0].dati_beni_servizi.dettaglio_linee[${index}].data_fine_periodo`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.data_fine_periodo",
                  width: {
                     md: 4,
                  },
               },*/
            ],
         }
      case "dati_riepilogo":
         return {
            key,
            sectionTranslationId: "gov_it.invoice_fix.sections.dati_riepilogo",
            fields: [
               {
                  type: "textfield",
                  format: "number",
                  name: `fattura_elettronica_body[0].dati_beni_servizi.dati_riepilogo[${index}].aliquota_iva`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.beni_servizi_aliquota_iva",
                  required: true,
                  width: {
                     md: 4,
                  },
               },
               {
                  type: "textfield",
                  format: "number",
                  name: `fattura_elettronica_body[0].dati_beni_servizi.dati_riepilogo[${index}].imponibile_importo`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.beni_servizi_imponibile_importo",
                  required: true,
                  width: {
                     md: 4,
                  },
               },
               {
                  type: "textfield",
                  format: "number",
                  name: `fattura_elettronica_body[0].dati_beni_servizi.dati_riepilogo[${index}].imposta`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.beni_servizi_imposta",
                  required: true,
                  width: {
                     md: 4,
                  },
               },
               {
                  type: "select",
                  options: [
                     {
                        label: "I - Esigibilità differita",
                        value: "I",
                     },
                     {
                        label: "D - Esigibilità immediata",
                        value: "D",
                     },
                     {
                        label: "S - Scissione dei pagamenti",
                        value: "S",
                     },
                  ],
                  name: `fattura_elettronica_body[0].dati_beni_servizi.dati_riepilogo[${index}].esigibilita_iva`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.esigibilita_iva",
                  width: {
                     md: 6,
                  },
               },
               {
                  type: "textfield",
                  name: `fattura_elettronica_body[0].dati_beni_servizi.dati_riepilogo[${index}].riferimento_normativo`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.riferimento_normativo",
                  width: {
                     md: 6,
                  },
               },
               {
                  name: `fattura_elettronica_body[0].dati_beni_servizi.dati_riepilogo[${index}].natura`,
                  labelTranslationId: "gov_it.invoice_fix.fields.natura",
                  width: {
                     md: 12,
                  },
                  type: "select",
                  options: naturaOptions,
               },
            ],
         }
      case "dati_pagamento":
         return getFieldsArrayDatiPagamentoBySectionKey(
            key,
            "dati_pagamento",
            index
         )
      case "dati_ordine_acquisto":
         return getFieldsArrayDatiGeneraliBySectionKey(
            key,
            "gov_it.invoice_fix.sections.dati_ordine_acquisto",
            "dati_ordine_acquisto",
            index
         )
      case "dati_contratto":
         return getFieldsArrayDatiGeneraliBySectionKey(
            key,
            "gov_it.invoice_fix.sections.dati_contratto",
            "dati_contratto",
            index
         )
      case "dati_ricezione":
         return getFieldsArrayDatiGeneraliBySectionKey(
            key,
            "gov_it.invoice_fix.sections.dati_ricezione",
            "dati_ricezione",
            index
         )
      case "dati_fatture_collegate":
         return getFieldsArrayDatiGeneraliBySectionKey(
            key,
            "gov_it.invoice_fix.sections.dati_fatture_collegate",
            "dati_fatture_collegate",
            index
         )
      case "dati_sal":
         return getFieldsArrayDatiGeneraliBySectionKey(
            key,
            "gov_it.invoice_fix.sections.dati_sal",
            "dati_sal",
            index
         )
      case "dati_convenzione":
         return getFieldsArrayDatiGeneraliBySectionKey(
            key,
            "gov_it.invoice_fix.sections.dati_convenzione",
            "dati_convenzione",
            index
         )
   }
}

const getFieldGroupsBySectionKeyToSimplifiedForm = (
   section: SectionKeyType<"simplified">,
   key: string,
   index: number
): FieldGroup => {
   switch (section) {
      case "dati_beni_servizi":
         return {
            key,
            sectionTranslationId:
               "gov_it.invoice_fix.sections.dati_beni_servizi",
            fields: [
               {
                  type: "textfield",
                  format: "number",
                  name: `fattura_elettronica_body[0].dati_beni_servizi[${index}].importo`,
                  labelTranslationId: "gov_it.invoice_fix.fields.importo",
                  required: true,
                  width: {
                     md: 3,
                  },
               },
               {
                  type: "textfield",
                  format: "number",
                  name: `fattura_elettronica_body[0].dati_beni_servizi[${index}].dati_iva.imposta`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.beni_servizi_imposta",
                  required: true,
                  width: {
                     md: 3,
                  },
               },
               {
                  type: "textfield",
                  format: "number",
                  name: `fattura_elettronica_body[0].dati_beni_servizi[${index}].dati_iva.aliquota`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.beni_servizi_aliquota_iva",
                  required: true,
                  width: {
                     md: 3,
                  },
               },
               {
                  name: `fattura_elettronica_body[0].dati_beni_servizi[${index}].natura`,
                  labelTranslationId: "gov_it.invoice_fix.fields.natura",
                  required: true,
                  width: {
                     md: 3,
                  },
                  type: "select",
                  options: naturaOptions,
               },
               {
                  type: "textfield",
                  name: `fattura_elettronica_body[0].dati_beni_servizi[${index}].descrizione`,
                  labelTranslationId: "gov_it.invoice_fix.fields.descrizione",
                  required: true,
                  width: {
                     md: 12,
                  },
               },
               {
                  type: "textfield",
                  name: `fattura_elettronica_body[0].dati_beni_servizi[${index}].riferimento_normativo`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.riferimento_normativo",
                  width: {
                     md: 12,
                  },
               },
            ],
         }
   }
}

const getFieldGroupsBySectionKey = <T extends FormInvoiceType>(
   type: T,
   section: SectionKeyType<T>,
   key: string,
   index: number
): FieldGroup => {
   switch (type) {
      case "simplified":
         return getFieldGroupsBySectionKeyToSimplifiedForm(
            section as SectionKeyType<"simplified">,
            key,
            index
         )
      case "simple":
      default:
         return getFieldGroupsBySectionKeyToSimpleForm(
            section as SectionKeyType<"simple">,
            key,
            index
         )
   }
}

export const buildSubSectionDatiPagamento = (
   sectionKey: "dettaglio_pagamento",
   ChildIndex: number,
   parentIndex: number
): FieldGroup => {
   switch (sectionKey) {
      case "dettaglio_pagamento":
         return {
            key: `dettaglio_pagamento`,
            sectionTranslationId:
               "gov_it.invoice_fix.sections.dettaglio_pagamento",
            fields: [
               {
                  type: "select",
                  options: modalitaPagamentoOptions,
                  name: `fattura_elettronica_body[0].dati_pagamento[${parentIndex}].${sectionKey}[${ChildIndex}].modalita_pagamento`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.dati_pagamento_modalita",
                  required: true,
                  width: {
                     md: 6,
                  },
               },
               {
                  type: "textfield",
                  format: "number",
                  name: `fattura_elettronica_body[0].dati_pagamento[${parentIndex}].${sectionKey}[${ChildIndex}].importo_pagamento`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.dati_pagamento_importo",
                  required: true,
                  width: {
                     md: 6,
                  },
               },
               {
                  type: "textfield",
                  name: `fattura_elettronica_body[0].dati_pagamento[${parentIndex}].${sectionKey}[${ChildIndex}].beneficiario`,
                  labelTranslationId: "gov_it.invoice_fix.fields.beneficiario",
                  width: {
                     md: 8,
                  },
                  inputProps: {
                     maxLength: 200,
                  },
               },
               {
                  type: "textfield",
                  name: `fattura_elettronica_body[0].dati_pagamento[${parentIndex}].${sectionKey}[${ChildIndex}].codice_pagamento`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.codice_pagamento",
                  width: {
                     md: 4,
                  },
                  inputProps: {
                     maxLength: 60,
                  },
               },
               {
                  type: "date",
                  name: `fattura_elettronica_body[0].dati_pagamento[${parentIndex}].${sectionKey}[${ChildIndex}].data_riferimento_termini_pagamento`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.data_riferimento_termini_pagamento",
                  width: {
                     md: 4,
                  },
               },
               {
                  type: "date",
                  name: `fattura_elettronica_body[0].dati_pagamento[${parentIndex}].${sectionKey}[${ChildIndex}].data_scadenza_pagamento`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.dati_pagamento_data_scadenza",
                  width: {
                     md: 4,
                  },
               },
               {
                  type: "textfield",
                  name: `fattura_elettronica_body[0].dati_pagamento[${parentIndex}].${sectionKey}[${ChildIndex}].giorni_termini_pagamento`,
                  labelTranslationId:
                     "gov_it.invoice_fix.fields.giorni_termini_pagamento",
                  width: {
                     md: 4,
                  },
               },
               {
                  type: "textfield",
                  name: `fattura_elettronica_body[0].dati_pagamento[${parentIndex}].${sectionKey}[${ChildIndex}].iban`,
                  labelTranslationId: "gov_it.invoice_fix.fields.iban",
                  width: {
                     md: 8,
                  },
                  inputProps: {
                     maxLength: 34,
                     minLength: 15,
                  },
               },
               {
                  type: "textfield",
                  name: `fattura_elettronica_body[0].dati_pagamento[${parentIndex}].${sectionKey}[${ChildIndex}].abi`,
                  labelTranslationId: "gov_it.invoice_fix.fields.abi",
                  width: {
                     md: 4,
                  },
                  inputProps: {
                     maxLength: 5,
                     minLength: 5,
                  },
               },
               {
                  type: "textfield",
                  name: `fattura_elettronica_body[0].dati_pagamento[${parentIndex}].${sectionKey}[${ChildIndex}].bic`,
                  labelTranslationId: "gov_it.invoice_fix.fields.bic",
                  width: {
                     md: 6,
                  },
                  inputProps: {
                     minLength: 8,
                  },
               },
               {
                  type: "textfield",
                  name: `fattura_elettronica_body[0].dati_pagamento[${parentIndex}].${sectionKey}[${ChildIndex}].cab`,
                  labelTranslationId: "gov_it.invoice_fix.fields.cab",
                  width: {
                     md: 6,
                  },
                  inputProps: {
                     maxLength: 5,
                     minLength: 5,
                  },
               },
            ],
            isChildVariable: true,
         }
   }
}

export const buildGeneralDataSection = <T extends FormInvoiceType>(
   type: T,
   section: SectionKeyType<T>,
   key: string,
   index: number
): FieldGroup => {
   return getFieldGroupsBySectionKey(type, section, key, index)
}

const formFieldsSimpleGrouped: FieldGroup[] = [
   {
      key: "dati_trasmissione",
      sectionTranslationId: "gov_it.invoice_fix.sections.dati_trasmissione",
      fields: [
         {
            type: "textfield",
            name: "fattura_elettronica_header.dati_trasmissione.codice_destinatario",
            labelTranslationId: "gov_it.invoice_fix.fields.codice_destinatario",
            width: {
               md: 12,
            },
            required: true,
            inputProps: {
               maxLength: 7,
            },
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.dati_trasmissione.pec_destinatario",
            labelTranslationId: "gov_it.invoice_fix.fields.pec_destinatario",
            width: {
               md: 6,
            },
         },
         {
            type: "select",
            options: [
               {
                  label: "FPA12 - Fattura verso PA",
                  value: "FPA12",
               },
               {
                  label: "FPR12 - Fattura verso privati",
                  value: "FPR12",
               },
            ],
            name: "fattura_elettronica_header.dati_trasmissione.formato_trasmissione",
            labelTranslationId:
               "gov_it.invoice_fix.fields.formato_trasmissione",
            width: {
               md: 6,
            },
         },
      ],
   },
   {
      key: "cedente_prestatore",
      sectionTranslationId: "gov_it.invoice_fix.sections.cedente_prestatore",
      fields: [
         {
            type: "textfield",
            name: "fattura_elettronica_header.cedente_prestatore.dati_anagrafici.id_fiscale_iva.id_paese",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cedente_prestatore_id_paese",
            width: {
               md: 3,
            },
            format: "uppercase",
            required: true,
            inputProps: {
               maxLength: 2,
            },
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cedente_prestatore.dati_anagrafici.id_fiscale_iva.id_codice",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cedente_prestatore_id_codice",
            width: {
               md: 5,
            },
            format: "uppercase",
            inputProps: {
               maxLength: 11,
            },
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cedente_prestatore.dati_anagrafici.codice_fiscale",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cedente_prestatore_codice_fiscale",
            width: {
               md: 4,
            },
            format: "uppercase",
            inputProps: {
               maxLength: 16,
            },
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cedente_prestatore.dati_anagrafici.anagrafica.denominazione",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cedente_prestatore_denominazione",
            required: true,
            width: {
               md: 4,
            },
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cedente_prestatore.dati_anagrafici.anagrafica.nome",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cedente_prestatore_nome",
            required: true,
            width: {
               md: 4,
            },
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cedente_prestatore.dati_anagrafici.anagrafica.cognome",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cedente_prestatore_cognome",
            required: true,
            width: {
               md: 4,
            },
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cedente_prestatore.sede.nazione",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cedente_prestatore_sede_nazione",
            width: {
               md: 2,
            },
            format: "uppercase",
            inputProps: {
               maxLength: 2,
            },
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cedente_prestatore.sede.indirizzo",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cedente_prestatore_sede_indirizzo",
            width: {
               md: 4,
            },
            format: "uppercase",
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cedente_prestatore.sede.comune",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cedente_prestatore_sede_comune",
            width: {
               md: 2,
            },
            format: "uppercase",
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cedente_prestatore.sede.provincia",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cedente_prestatore_sede_provincia",
            required: true,
            width: {
               md: 2,
            },
            format: "uppercase",
            inputProps: {
               maxLength: 2,
            },
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cedente_prestatore.sede.cap",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cedente_prestatore_sede_cap",
            width: {
               md: 2,
            },
            inputProps: {
               maxLength: 5,
            },
            required: true,
         },
         {
            type: "select",
            options: regisFiscaliOptions,
            name: "fattura_elettronica_header.cedente_prestatore.dati_anagrafici.regime_fiscale",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cedente_prestatore_regime_fiscale",
            width: {
               md: 12,
            },
            required: true,
         },
      ],
   },
   {
      key: "cessionario_committente",
      sectionTranslationId:
         "gov_it.invoice_fix.sections.cessionario_committente",
      fields: [
         {
            type: "textfield",
            name: "fattura_elettronica_header.cessionario_committente.dati_anagrafici.id_fiscale_iva.id_paese",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cessionario_committente_id_paese",
            width: {
               md: 3,
            },
            format: "uppercase",
            required: true,
            inputProps: {
               maxLength: 2,
            },
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cessionario_committente.dati_anagrafici.id_fiscale_iva.id_codice",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cessionario_committente_id_codice",
            width: {
               md: 5,
            },
            format: "uppercase",
            inputProps: {
               maxLength: 11,
            },
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cessionario_committente.dati_anagrafici.codice_fiscale",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cessionario_committente_codice_fiscale",
            width: {
               md: 4,
            },
            format: "uppercase",
            inputProps: {
               maxLength: 16,
            },
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cessionario_committente.dati_anagrafici.anagrafica.denominazione",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cessionario_committente_denominazione",
            width: {
               md: 4,
            },
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cessionario_committente.dati_anagrafici.anagrafica.nome",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cessionario_committente_nome",
            width: {
               md: 4,
            },
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cessionario_committente.dati_anagrafici.anagrafica.cognome",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cessionario_committente_cognome",
            width: {
               md: 4,
            },
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cessionario_committente.dati_anagrafici.anagrafica.titolo",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cessionario_committente_titolo",
            width: {
               md: 6,
            },
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cessionario_committente.dati_anagrafici.anagrafica.cod_eori",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cessionario_committente_cod_eori",
            width: {
               md: 6,
            },
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cessionario_committente.sede.nazione",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cessionario_committente_sede_nazione",
            width: {
               md: 2,
            },
            format: "uppercase",
            inputProps: {
               maxLength: 2,
            },
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cessionario_committente.sede.indirizzo",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cessionario_committente_sede_indirizzo",
            width: {
               md: 4,
            },
            format: "uppercase",
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cessionario_committente.sede.comune",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cessionario_committente_sede_comune",
            width: {
               md: 2,
            },
            format: "uppercase",
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cessionario_committente.sede.provincia",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cessionario_committente_sede_provincia",
            width: {
               md: 2,
            },
            format: "uppercase",
            inputProps: {
               maxLength: 2,
            },
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cessionario_committente.sede.cap",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cessionario_committente_sede_cap",
            width: {
               md: 2,
            },
            inputProps: {
               maxLength: 5,
            },
            required: true,
         },
      ],
   },
   {
      key: "dati_generali_documento",
      sectionTranslationId:
         "gov_it.invoice_fix.sections.dati_generali_documento",
      fields: [
         {
            type: "select",
            options: tipoDocumentoSimpleOptions,
            name: "fattura_elettronica_body[0].dati_generali.dati_generali_documento.tipo_documento",
            labelTranslationId:
               "gov_it.invoice_fix.fields.documento_tipo_documento",
            width: {
               md: 12,
            },
            required: true,
         },
         {
            type: "date",
            name: "fattura_elettronica_body[0].dati_generali.dati_generali_documento.data",
            labelTranslationId: "gov_it.invoice_fix.fields.documento_data",
            width: {
               md: 4,
            },
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_body[0].dati_generali.dati_generali_documento.numero",
            labelTranslationId: "gov_it.invoice_fix.fields.documento_numero",
            width: {
               md: 4,
            },
            required: true,
         },
         {
            name: "fattura_elettronica_body[0].dati_generali.dati_generali_documento.divisa",
            labelTranslationId: "gov_it.invoice_fix.fields.documento_divisa",
            width: {
               md: 4,
            },
            required: true,
            type: "select",
            default: {
               value: "EUR",
               label: "EUR",
            },
            options: [
               { label: "USD", value: "USD" },
               { label: "EUR", value: "EUR" },
               { label: "JPY", value: "JPY" },
               { label: "GBP", value: "GBP" },
               { label: "AUD", value: "AUD" },
               { label: "CAD", value: "CAD" },
               { label: "CHF", value: "CHF" },
               { label: "CNY", value: "CNY" },
               { label: "SEK", value: "SEK" },
               { label: "NZD", value: "NZD" },
               { label: "MXN", value: "MXN" },
               { label: "SGD", value: "SGD" },
               { label: "HKD", value: "HKD" },
               { label: "NOK", value: "NOK" },
               { label: "KRW", value: "KRW" },
               { label: "TRY", value: "TRY" },
               { label: "RUB", value: "RUB" },
               { label: "INR", value: "INR" },
               { label: "BRL", value: "BRL" },
               { label: "ZAR", value: "ZAR" },
            ],
         },
         {
            type: "textfield",
            format: "number",
            name: "fattura_elettronica_body[0].dati_generali.dati_generali_documento.importo_totale_documento",
            labelTranslationId:
               "gov_it.invoice_fix.fields.documento_importo_totale",
            width: {
               md: 4,
            },
         },
         {
            type: "select",
            options: [
               {
                  label: "SI",
                  value: "SI",
               },
            ],
            name: "fattura_elettronica_body[0].dati_generali.dati_generali_documento.dati_bollo.bollo_virtuale",
            labelTranslationId: "gov_it.invoice_fix.fields.bollo_virtuale",
            width: {
               md: 4,
            },
         },
         {
            type: "textfield",
            format: "number",
            name: "fattura_elettronica_body[0].dati_generali.dati_generali_documento.dati_bollo.importo_bollo",
            labelTranslationId: "gov_it.invoice_fix.fields.importo_bollo",
            width: {
               md: 4,
            },
         },
         {
            type: "arrayTextfield",
            name: "fattura_elettronica_body[0].dati_generali.dati_generali_documento.causale",
            labelTranslationId: "gov_it.invoice_fix.fields.causale",
            width: {
               md: 12,
            },
         },
      ],
   },
]

const formFieldsSimplifiedGrouped: FieldGroup[] = [
   {
      key: "dati_trasmissione",
      sectionTranslationId: "gov_it.invoice_fix.sections.dati_trasmissione",
      fields: [
         {
            type: "textfield",
            name: "fattura_elettronica_header.dati_trasmissione.codice_destinatario",
            labelTranslationId: "gov_it.invoice_fix.fields.codice_destinatario",
            width: {
               md: 12,
            },
            inputProps: {
               maxLength: 7,
            },
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.dati_trasmissione.id_trasmittente.id_paese",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cedente_prestatore_id_paese",
            width: {
               md: 2,
            },
            format: "uppercase",
            inputProps: {
               maxLength: 2,
            },
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.dati_trasmissione.id_trasmittente.id_codice",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cedente_prestatore_id_codice",
            width: {
               md: 3,
            },
            format: "uppercase",
            inputProps: {
               maxLength: 11,
            },
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.dati_trasmissione.pec_destinatario",
            labelTranslationId: "gov_it.invoice_fix.fields.pec_destinatario",
            width: {
               md: 3,
            },
         },
         {
            type: "select",
            default: {
               label: "FSM10 - Fattura semplificata verso PA",
               value: "FSM10",
            },
            options: [
               {
                  label: "FSM10 - Fattura semplificata verso PA",
                  value: "FSM10",
               },
            ],
            name: "fattura_elettronica_header.dati_trasmissione.formato_trasmissione",
            labelTranslationId:
               "gov_it.invoice_fix.fields.formato_trasmissione",
            width: {
               md: 4,
            },
         },
      ],
   },
   {
      key: "cedente_prestatore",
      sectionTranslationId: "gov_it.invoice_fix.sections.cedente_prestatore",
      fields: [
         {
            type: "textfield",
            name: "fattura_elettronica_header.cedente_prestatore.id_fiscale_iva.id_paese",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cedente_prestatore_id_paese",
            width: {
               md: 3,
            },
            format: "uppercase",
            inputProps: {
               maxLength: 2,
            },
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cedente_prestatore.id_fiscale_iva.id_codice",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cedente_prestatore_id_codice",
            width: {
               md: 5,
            },
            format: "uppercase",
            inputProps: {
               maxLength: 11,
            },
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cedente_prestatore.codice_fiscale",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cedente_prestatore_codice_fiscale",
            width: {
               md: 4,
            },
            format: "uppercase",
            inputProps: {
               maxLength: 16,
            },
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cedente_prestatore.denominazione",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cedente_prestatore_denominazione",
            required: true,
            width: {
               md: 4,
            },
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cedente_prestatore.nome",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cedente_prestatore_nome",
            required: true,
            width: {
               md: 4,
            },
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cedente_prestatore.cognome",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cedente_prestatore_cognome",
            required: true,
            width: {
               md: 4,
            },
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cedente_prestatore.sede.nazione",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cedente_prestatore_sede_nazione",
            width: {
               md: 2,
            },
            format: "uppercase",
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cedente_prestatore.sede.indirizzo",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cedente_prestatore_sede_indirizzo",
            width: {
               md: 4,
            },
            format: "uppercase",
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cedente_prestatore.sede.comune",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cedente_prestatore_sede_comune",
            width: {
               md: 2,
            },
            format: "uppercase",
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cedente_prestatore.sede.provincia",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cedente_prestatore_sede_provincia",
            required: true,
            width: {
               md: 2,
            },
            format: "uppercase",
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cedente_prestatore.sede.cap",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cedente_prestatore_sede_cap",
            width: {
               md: 2,
            },
            required: true,
         },
         {
            type: "select",
            options: regisFiscaliOptions,
            name: "fattura_elettronica_header.cedente_prestatore.regime_fiscale",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cedente_prestatore_regime_fiscale",
            width: {
               md: 12,
            },
            required: true,
         },
      ],
   },
   {
      key: "cessionario_committente",
      sectionTranslationId:
         "gov_it.invoice_fix.sections.cessionario_committente",
      fields: [
         {
            type: "textfield",
            name: "fattura_elettronica_header.cessionario_committente.identificativi_fiscali.id_fiscale_iva.id_paese",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cessionario_committente_id_paese",
            width: {
               md: 3,
            },
            format: "uppercase",
            inputProps: {
               maxLength: 2,
            },
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cessionario_committente.identificativi_fiscali.id_fiscale_iva.id_codice",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cessionario_committente_id_codice",
            width: {
               md: 5,
            },
            format: "uppercase",
            inputProps: {
               maxLength: 11,
            },
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cessionario_committente.altri_dati_identificativi.codice_fiscale",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cessionario_committente_codice_fiscale",
            width: {
               md: 4,
            },
            format: "uppercase",
            required: true,
            inputProps: {
               maxLength: 16,
            },
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cessionario_committente.altri_dati_identificativi.denominazione",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cessionario_committente_denominazione",
            width: {
               md: 4,
            },
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cessionario_committente.altri_dati_identificativi.nome",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cessionario_committente_nome",
            width: {
               md: 4,
            },
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cessionario_committente.altri_dati_identificativi.cognome",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cessionario_committente_cognome",
            width: {
               md: 4,
            },
            required: true,
         },
         // {
         //    type: "textfield",
         //    name: "fattura_elettronica_header.cessionario_committente.titolo",
         //    labelTranslationId:
         //       "gov_it.invoice_fix.fields.cessionario_committente_titolo",
         //    width: {
         //       md: 6,
         //    },
         // },
         // {
         //    type: "textfield",
         //    name: "fattura_elettronica_header.cessionario_committente.cod_eori",
         //    labelTranslationId:
         //       "gov_it.invoice_fix.fields.cessionario_committente_cod_eori",
         //    width: {
         //       md: 6,
         //    },
         // },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cessionario_committente.altri_dati_identificativi.sede.nazione",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cessionario_committente_sede_nazione",
            width: {
               md: 2,
            },
            format: "uppercase",
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cessionario_committente.altri_dati_identificativi.sede.indirizzo",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cessionario_committente_sede_indirizzo",
            width: {
               md: 4,
            },
            format: "uppercase",
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cessionario_committente.altri_dati_identificativi.sede.comune",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cessionario_committente_sede_comune",
            width: {
               md: 2,
            },
            format: "uppercase",
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cessionario_committente.altri_dati_identificativi.sede.provincia",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cessionario_committente_sede_provincia",
            width: {
               md: 2,
            },
            format: "uppercase",
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_header.cessionario_committente.altri_dati_identificativi.sede.cap",
            labelTranslationId:
               "gov_it.invoice_fix.fields.cessionario_committente_sede_cap",
            width: {
               md: 2,
            },
            required: true,
         },
      ],
   },
   {
      key: "dati_generali_documento",
      sectionTranslationId:
         "gov_it.invoice_fix.sections.dati_generali_documento",
      fields: [
         {
            type: "select",
            options: tipoDocumentoSimplifiedOptions,
            name: "fattura_elettronica_body[0].dati_generali.dati_generali_documento.tipo_documento",
            labelTranslationId:
               "gov_it.invoice_fix.fields.documento_tipo_documento",
            width: {
               md: 12,
            },
            required: true,
         },
         {
            type: "date",
            name: "fattura_elettronica_body[0].dati_generali.dati_generali_documento.data",
            labelTranslationId: "gov_it.invoice_fix.fields.documento_data",
            width: {
               md: 4,
            },
            required: true,
         },
         {
            type: "textfield",
            name: "fattura_elettronica_body[0].dati_generali.dati_generali_documento.numero",
            labelTranslationId: "gov_it.invoice_fix.fields.documento_numero",
            width: {
               md: 4,
            },
            required: true,
         },
         {
            name: "fattura_elettronica_body[0].dati_generali.dati_generali_documento.divisa",
            labelTranslationId: "gov_it.invoice_fix.fields.documento_divisa",
            width: {
               md: 4,
            },
            required: true,

            type: "select",
            default: {
               value: "EUR",
               label: "EUR",
            },
            options: currencyOptions,
         },
      ],
   },
]

export const getCommonFieldsGrouped = <T extends FormInvoiceType>(
   type: T
): FieldGroup[] => {
   switch (type) {
      case "simplified":
         return formFieldsSimplifiedGrouped
      case "simple":
      default:
         return formFieldsSimpleGrouped
   }
}

export const buildCedentePrestatoreInvoicePayload = (
   userData: User | null
): CedentePrestratoreInvoicePayload => {
   return {
      contatti: null,
      iscrizione_rea: null,
      riferimento_amministrazione: "",
      stabile_organizzazione: null,
      dati_anagrafici: {
         id_fiscale_iva: {
            id_paese: userData?.invoicing_country || "",
            id_codice: userData?.invoicing_vat_number || "",
         },
         codice_fiscale: userData?.invoicing_fiscal_id || "",
         anagrafica: {
            denominazione: userData?.invoicing_name || "",
            nome: null,
            cognome: null,
            cod_eori: null,
            titolo: null,
         },
         regime_fiscale: "",
         albo_professionale: null,
         provincia_albo: null,
         numero_iscrizione_albo: null,
         data_iscrizione_albo: null,
      },
      sede: {
         nazione: userData?.invoicing_country?.toUpperCase() || "",
         indirizzo: userData?.invoicing_address?.toUpperCase() || "",
         comune: userData?.invoicing_city?.toUpperCase() || "",
         provincia: userData?.invoicing_province?.toUpperCase() || "",
         cap: userData?.invoicing_cap?.toUpperCase() || "",
         numero_civico: null,
      },
   }
}

export const buildCedentePrestatoreInvoicePayloadSimplified = (
   userData: User | null
): CedentePrestatoreInvoiceSimplifiedPayload => {
   if (!userData) {
      return null
   }

   return {
      id_fiscale_iva: {
         id_paese: userData.invoicing_country || "",
         id_codice: userData.invoicing_vat_number || "",
      },
      codice_fiscale: userData.invoicing_fiscal_id || null,
      denominazione: userData.invoicing_name || null,
      nome: null,
      cognome: null,
      sede: {
         nazione: userData?.invoicing_country?.toUpperCase() || "",
         indirizzo: userData?.invoicing_address?.toUpperCase() || "",
         comune: userData?.invoicing_city?.toUpperCase() || "",
         provincia: userData?.invoicing_province?.toUpperCase() || "",
         cap: userData?.invoicing_cap?.toUpperCase() || "",
         numero_civico: null,
      },
      stabile_organizzazione: null,
      rappresentante_fiscale: null,
      iscrizione_rea: null,
      regime_fiscale: "",
   }
}
